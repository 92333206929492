import { useState } from 'react';
import { newsletterRegister } from '../../service/newsletterService';
import { Oval } from 'react-loader-spinner'

const Newsletter = () => {

    const [ formData, setFormData ] = useState({
        name: '',
        email: ''
    });

    const [ loading, setLoading ] = useState(false);

    const {
        name,
        email
    } = formData;

    const onChange = (e:any) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const onSubmit = async (e:any) => {
        e.preventDefault();
        if(name != '' && email != '') {
            setLoading(true);

            await newsletterRegister(formData);

            setFormData({
                name: '',
                email: ''
            });

            setLoading(false);
        } else {

        }
    }

    return (
        <div className="bg-[#CCCCCC] mt-4 p-4 pb-0 flex flex-col justify-center items-center lg:mt-10">
            <h1 className="text-xl font-['Montserrat'] font-light text-center text-[#8b716f]">Inscreva-se para receber as últimas novidades.</h1>
            <form onSubmit={ e => onSubmit(e) } className='mt-2 w-full sm:w-10/12 md:w-1/2 lg:flex lg:w-full lg:justify-center lg:items-center'>
                <div className='flex -mx-3 lg:w-1/3'>
                    <div className='w-full px-3 mb-5'>
                        <div className='flex'>
                            <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                            <input value={ name } onChange={ e => onChange(e) } type="text" name='name' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite seu nome completo" required />
                        </div>
                    </div>
                </div>
                <div className='flex -mx-3 lg:ml-3 lg:w-1/3'>
                    <div className='w-full px-3 mb-5'>
                        <div className='flex'>
                            <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                            </div>
                            <input value={ email } onChange={ e => onChange(e) } type="email" name='email' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite seu Email" required />
                        </div>
                    </div>
                </div>
                <div className="flex -mx-3 lg:ml-3">
                    <div className="w-full px-3 mb-5">
                        {
                            loading ? (
                                <button type="button" className='bg-[#8b716f] border text-white text-xs uppercase py-2 px-4 rounded-md hover:border-[#8b716f] hover:bg-white hover:text-[#8b716f] transition-all ease-in-out duration-200'>
                                    <Oval color='#FFFFFF' width={20} height={20} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                                </button>
                            ) : (
                                <button type='submit' className="block w-full max-w-xs mx-auto bg-[#eec5d4] text-[#8b716f] hover:bg-[#8b716f] border-[#8b716f] border hover:text-[#FFFFFF] rounded-lg px-3 py-2 font-semibold transition-all duration-300 md:w-3/6 lg:w-full text-sm outline-none">Inscrever-se</button>
                            )
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Newsletter;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { alerModel } from '../../../models/alert-models';

const initialState:alerModel = {
    alertType: null,
    message: null 
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        setAlert: (state, action: PayloadAction<alerModel | null>) => {
            state.alertType = action.payload?.alertType;
            state.message = action.payload?.message;
        },
        removeAlert: (state, action: PayloadAction<alerModel | null>) => {
            state.alertType = action.payload?.alertType;
        }
    }
});

export default alertSlice.reducer;

export const { setAlert, removeAlert } = alertSlice.actions;
import { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/hooks';
import { useSelector } from 'react-redux';
import Layout from '../../hocs/Layout';
import logoAlpha from '../../assets/global/logoalpha.png';
import { Oval } from 'react-loader-spinner'
import { Navigate } from 'react-router-dom';
import { confirmNewPassword } from '../../service/userService';
import { setAlert, removeAlert } from '../../redux/slice/commons/alertSlice';

const ResetPasswordConfirmPage = () => {
    
    const inputPassword = useRef(document.createElement("input"));
    const params = useParams();
    const [ searchParams ] = useSearchParams();
    const userDispatch = useAppDispatch();
    const alertDispatch = useAppDispatch();

    const [ newPassword, setNewPassword ] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
        inputPassword.current.focus();
    },[]);

    const { user } = useSelector((state:any) => state);

    const [ formData, setFormData ] = useState({
        passwordInput: '',
        confirmPasswordInput: ''
    });

    const {
        passwordInput,
        confirmPasswordInput
    } = formData;

    const onChange = (e:any) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e:any) => {
        e.preventDefault();

        if(passwordInput != confirmPasswordInput) {
            
            const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

            window.scrollTo(0,0)

            alertDispatch(setAlert({alertType: 'red', message: 'As senhas não correspondem, verifique.'}));

            setTimeout(() => {
                alertDispatch(removeAlert({alertType: null, message: null}))
            }, time);
            
        } else {
            const uid = params.uid;
            const token = params.token;
            const expires = searchParams.get('expires');
            const signature = searchParams.get('signature');

            userDispatch(confirmNewPassword({
                'uid': uid,
                'token': token,
                'expires': expires,
                'signature': signature,
                'password': passwordInput,
                'password_confirmation': confirmPasswordInput
            }));

            setNewPassword(true);
        }
    }

    if(newPassword && !user.loading) {
        return <Navigate to='/' />
    }

    return (
        <Layout>
            <div className='min-w-screen min-h-screen flex items-center justify-center px-5 py-5'>
                <div className='bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden max-w-screen-sm'>
                    <div className='md:flex w-full'>
                        <div className='w-full py-10 px-5 md:px-10'>
                            <div className='flex justify-center items-center'>
                                <img src={ logoAlpha } className='w-60' />
                            </div>
                            <div className='mb-5 text-center'>
                                <h1 className='font-bold text-3xl text-gray-900'>Definir uma nova senha</h1>
                            </div>
                            <div>
                                <form onSubmit={ e => onSubmit(e) }>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='passwordInput' className='text-xs font-semibold px-1'>Password:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                    </svg>
                                                </div>
                                                <input ref={inputPassword} value={ passwordInput } onChange={ e => onChange(e) } type="password" name='passwordInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite sua nova senha de acesso" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='confirmPasswordInput' className='text-xs font-semibold px-1'>Digite novamente sua nova Senha de Acesso:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                    </svg>
                                                </div>
                                                <input value={ confirmPasswordInput } onChange={ e => onChange(e) } type="password" name='confirmPasswordInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Re Digite sua nova senha de acesso" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-5">
                                        {user.loading ?
                                            <button className="w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300 flex justify-center items-center">
                                                <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                                            </button>
                                            :
                                            <button type='submit' className=" block w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300">Redefinir Senha</button>
                                        }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ResetPasswordConfirmPage
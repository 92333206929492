import Layout from "../hocs/Layout";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux/hooks';
import { getProductsDetails } from "../service/productService";
import { addItemCart, getItemsCart, getTotalCart, getItemTotalCart } from "../service/cartService";
import { useSelector } from 'react-redux';
import { StarIcon } from "@heroicons/react/24/solid";
import ProductCard from "../components/common/productcard";
import { Link, useNavigate } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

/*const Slides = [
    {
        url: 'https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2620&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80',
    },
];*/

const ProductDetails = {
    id: '',
    sku: '',
    name: '',
    description: '',
    regular_price: 0,
    sale_price: 0,
    stock_status: '',
    featured: 0,
    highlights: '',
    quantity: '',
    category_id: 0,
    images: ''
}

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
}

const Product = () => {

    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ quantity, setQuantity ] = useState(1);
    const params = useParams();
    const product_slug = params.slug;
    const Slides: any = [];

    const productDispatch = useAppDispatch();
    const cartDispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
        productDispatch(getProductsDetails({ 'product':product_slug }))
    }, [product_slug]);

    const { products } = useSelector((state:any) => state);
    const { loading } = useSelector((state:any) => state);

    const onChange = (e:any) => {
        setQuantity(e.target.value)
    }

    if(products.product !== null && products.product.product !== null && products.product.product !== undefined && products.product.product) {
        ProductDetails.id = products.product.product[0].id;
        ProductDetails.sku = products.product.product[0].sku;
        ProductDetails.name = products.product.product[0].name;
        ProductDetails.description = products.product.product[0].description;
        ProductDetails.regular_price = products.product.product[0].regular_price;
        ProductDetails.sale_price = products.product.product[0].sale_price;
        ProductDetails.stock_status = products.product.product[0].stock_status;
        ProductDetails.featured = products.product.product[0].featured;
        ProductDetails.highlights = products.product.product[0].highlights;
        ProductDetails.quantity = products.product.product[0].quantity;
        ProductDetails.images = products.product.product[0].images;
        ProductDetails.category_id = products.product.product[0].category_id;
    }

    if(ProductDetails.images != '') {
        let imagesObj = JSON.parse(ProductDetails.images);

        imagesObj.map((image: any) => {
            Slides.push(image);
        })
    }

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? Slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }

    const nextSlide = () => {
        const isLastSlide = currentIndex === Slides.length -1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }

    const PromocionalPrice = () =>{

        if(Number(ProductDetails.sale_price) < Number(ProductDetails.regular_price)) {

            let Percent = (ProductDetails.sale_price * 100) / ProductDetails.regular_price;
            let NegativePercent = (Percent - 100);

            return(
                <div className="bg-red-600 text-white text-xs py-1 px-2 rounded-md uppercase">{ NegativePercent.toFixed(0) } %</div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const ShowPrice = () => {

        if(Number(ProductDetails.sale_price > 0)) {
            return(
                <p className="text-xl font-bold text-gray-900"><span className="text-sm line-through text-gray-400">R$ { ProductDetails.regular_price }</span><span className="ml-2">R$ { ProductDetails.sale_price }</span></p>
            )
        } else {
            return (
                <p className="text-xl font-bold text-gray-900">R$ { ProductDetails.regular_price }</p>
            )
        }
    }

    const ShowStock = () => {
        
        if(Number(ProductDetails.quantity) > 0) {
            return(
                <p className="text-sm text-gray-900">Stock: <span className="text-green-600">Disponível. ({ ProductDetails.quantity } Unidades)</span></p>
            )
        } else {
            return (
                <p className="text-sm text-gray-900">Stock: <span className="text-red-600">Não disponível.</span></p>
            )
        }
    }

    const [ loadingBtn, setLoadingBtn ] = useState(false);

    const addToCart = async () => {
        if(products.product !== null && products.product.product !== null && products.product.product !== undefined && products.product.product && products.product.product[0].quantity > 0) {
            setLoadingBtn(true);
            await cartDispatch(addItemCart(products.product.product[0]));
            await cartDispatch(getItemsCart());
            await cartDispatch(getTotalCart());
            await cartDispatch(getItemTotalCart());
            setLoadingBtn(false);
            navigate('/cart');
        }
    }

    const CapitalizeFirstLetter = (Phrase: string) => {
        
        return Phrase.charAt(0).toUpperCase() + Phrase.slice(1);

    }

    return (
        <Layout>
            <div className="container">
                <div className="my-6 flex items-center flex-wrap text-[#8b716f]">
                    <span className="text-sm"><Link to='/'>Home</Link></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    {
                        products.product !== null && 
                        products.product.product &&
                        products.product.product !== null &&
                        products.product.product !== undefined && 
                        (
                            <span className="text-sm"><Link to={`/category/${products.product.product[0].category.slug}`}>{CapitalizeFirstLetter(products.product.product[0].category.name.toLowerCase())}</Link></span>   
                        )
                    }
                    
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>

                    {
                        products.product !== null && 
                        products.product.product &&
                        products.product.product !== null &&
                        products.product.product !== undefined &&
                        (
                            <span className="text-sm"><Link to={`/subcategory/${products.product.product[0].subcategory.slug}`}>{products.product.product[0].subcategory.name}</Link></span>   
                        )
                    }

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>

                    {
                        products.product !== null && 
                        products.product.product &&
                        products.product.product !== null &&
                        products.product.product !== undefined &&
                        (
                            <span className="text-sm"><Link to={`/subcategorysub/${products.product.product[0].subcategorysub.slug}`}>{products.product.product[0].subcategorysub.name}</Link></span>   
                        )
                    }

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm text-gray-400">{ ProductDetails.name }</span>
                </div>
                <div className="max-w-2xl max-auto py-8 px-4 md:max-w-7xl">
                    <div className="md:flex">
                        { loading.globalLoading && <div className="w-full"><Skeleton count={4} /></div> }
                        <div className="h-[320px] relative group md:h-[550px] md:w-1/2">
                            <div style={{ backgroundImage: Slides.length > 0 ? `url(${Slides[currentIndex].url})` : `url:` }} className="w-full h-full rounded-2xl bg-center bg-no-repeat duration-500 bg-contain"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onClick={ prevSlide } className="hidden group-hover:block w-8 h-8 absolute top-[40%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onClick={ nextSlide } className="hidden group-hover:block w-8 h-8 absolute top-[40%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                            <div className="mt-2 flex justify-center items-center">
                                { currentIndex + 1 }/{ Slides.length }
                            </div>
                        </div>
                        <div className="mt-14 sm:px-0 sm:mt-16 md:w-1/2 md:px-4 md:mt-2">
                            <div className="flex items-center mb-3">
                                {
                                    ProductDetails.featured > 0 && (
                                        <div className="bg-[#8b716f] text-white text-xs py-1 px-2 rounded-md uppercase mr-3">Novo</div>
                                    )
                                }
                                {
                                    ProductDetails.sale_price > 0 && (
                                        PromocionalPrice()
                                    )
                                }
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <div className="flex items-center">
                                        {
                                            [0,1,2,3,4].map((rating) => (
                                                <StarIcon key={rating} className={ classNames(2 > rating ? 'text-[#efb810]': 'text-gray-300', 'h-4 w-4 flex-shrink-0')} aria-hidden='true' />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <p className="text-gray-900 text-sm mt-4">Codigo: { ProductDetails.sku }</p>
                            <h1 className="mt-3 text-2xl font-light tracking-tight text-[#8b716f]">{ ProductDetails.name }</h1>
                            <div className="mt-3">
                                {
                                    ShowPrice()
                                }
                            </div>
                            <div className="mt-3">
                                {
                                    ShowStock()
                                }
                            </div>
                            <div className="mt-6">
                                <div className="text-base text-gray-600 space-y-6" dangerouslySetInnerHTML={{ __html:ProductDetails.description.substring(0, 150)+'...' }}/>
                            </div>
                            <div className="mt-4">
                                <div className="flex items-center">
                                    <span className="text-sm mr-3">Quantidade:</span>
                                    <div className="flex items-center border border-[#8b716f] rounded-md overflow-hidden w-24">
                                        <button type="button" className="bg-gray-300 font-bold h-full py-1 w-12 text-[#8b716f] cursor-pointer" onClick={() => setQuantity(quantity - 1)} disabled={ quantity === 1 }>-</button>
                                        <input className="w-full text-center py-1 text-sm" type="number" value={quantity} onChange={ e => onChange(e) } min={0} />
                                        <button type="button" className="bg-gray-300 font-bold h-full py-1 w-12 text-[#8b716f] cursor-pointer" onClick={() => setQuantity(quantity + 1)}>+</button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex mt-6 justify-between gap-4">
                                {
                                    loadingBtn ?
                                    <button type="button" className="flex justify-center items-center w-1/2 border border-[#8b716f] rounded-md py-2 bg-[#8b716f] text-white text-sm hover:bg-white hover:text-[#8b716f]">
                                        <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                                    </button>
                                    :
                                    <button type="button" onClick={addToCart} className="w-1/2 border border-[#8b716f] rounded-md py-2 bg-[#8b716f] text-white text-sm hover:bg-white hover:text-[#8b716f]">Adicionar ao carrinho</button>
                                }
                                
                                <button type="button" className="w-1/2 border border-[#8b716f] rounded-md py-2 text-sm hover:bg-[#8b716f] hover:text-white">Comprar agora</button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <h4 className="text-[#8b716f] text-center">Descrição do produto</h4>
                        <div className="text-base text-gray-600 space-y-6" dangerouslySetInnerHTML={{ __html:ProductDetails.description }}/>
                    </div>
                    <div className="mt-6">
                        <h4 className="text-[#8b716f] text-center">Detalhes do produto</h4>
                        <div className="flex border-[#8b716f] border mt-2 flex-col">
                            <div className="flex w-full bg-gray-100">
                                <div className="text-sm flex items-center w-1/2 bg-gray-300 py-1 pl-1">Código</div>
                                <div className="text-sm flex items-center w-1/2 py-1 pl-1">{ ProductDetails.id }</div>
                            </div>
                            <div className="flex w-full bg-gray-100">
                                <div className="text-sm flex items-center w-1/2 py-1 pl-1">SKU</div>
                                <div className="text-sm flex items-center w-1/2 bg-gray-300 py-1 pl-1">{ ProductDetails.sku }</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <h4 className="text-[#8b716f] text-center">Produtos relacionados</h4>
                        { loading.globalLoading && <Skeleton count={4} /> }
                        <div className="mt-4">
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={40}
                                slidesPerView={4}
                                navigation={true}
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                    },
                                }}
                            >
                                {
                                    products.product !== null && 
                                    products.product.related !== null && 
                                    products.product.related !== undefined && 
                                    products.product.related &&
                                    products.product.related.map((product:any) => {
                                        if(product.status == 1) {
                                            if(product.id != ProductDetails.id) {
                                                return (
                                                    <SwiperSlide key={product.id}>
                                                        <ProductCard id={product.id} name={product.name} slug={product.slug} image={process.env.REACT_APP_API_URL + '/' +product.image} price={product.regular_price} sale_price={ product.sale_price } product={product} />
                                                    </SwiperSlide>
                                                )
                                            }
                                        }
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Product;
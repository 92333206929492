import axiosInstance from './api';
import { AxiosResponse, AxiosError } from 'axios';
import { Thunk } from '../redux/store';
import { setCategoryRequest, setCategoryRequestFail } from '../redux/slice/products/categorySlice';

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const getCategories = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get('/api/category/categories', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setCategoryRequest(response.data.categories));

        return response;
    } catch(error: any) {
        dispatch(setCategoryRequestFail(error));

        return error as AxiosError;
    }
}
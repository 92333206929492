import axiosInstance from "./api";
import { AxiosResponse, AxiosError } from "axios";
import { Thunk } from "../redux/store";
import { getAllOrders, emptyOrders } from "../redux/slice/orders/orderSlice";

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const getOrders = (user_id: any, token: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    await csrf();

    let dataActive = {
        'user_id': user_id
    }

    try {
        const response: AxiosResponse = await axiosInstance.post('/api/orders/get-orders', dataActive, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        dispatch(getAllOrders(response.data));

        return response;
    } catch(error: any) {
        dispatch(emptyOrders(null));

        return error as AxiosError;
    }
}
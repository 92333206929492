import { ChevronDownIcon, Bars3Icon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { HeartIcon, ShoppingCartIcon, MagnifyingGlassIcon, CubeIcon } from '@heroicons/react/24/outline';
import Alert from '../alert';
import aparel from '../../assets/products/apparel4.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { logout } from '../../service/userService';
import logoAlpha from '../../assets/global/logoalpha.png';
import { useAppDispatch } from '../../hooks/redux/hooks';
import Beauty from '../../assets/menu/menu_bg1.jpg';

export const Navbar = () => {
    
    const { user } = useSelector((state:any) => state);
    const { categories } = useSelector((state:any) => state);
    const { cart } = useSelector((state:any) => state);
    const { wishlist } = useSelector((state:any) => state);

    const navigate = useNavigate();

    const userDispatch = useAppDispatch();

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const logoutHandler = (e: any) => {
        e.preventDefault();
        userDispatch(logout());
        navigate('/');
    }

    const showMobileMenu = (e: any) => {
        e.preventDefault();
        document.querySelector('.site')?.classList.toggle('showmenu');
    }

    const removeMobileMenu = (e: any) => {
        e.preventDefault();
        document.querySelector('.site')?.classList.remove('showmenu');
    }

    const submenu = document.querySelectorAll('.has-child .icon-small');

    const toggle = (e: any) =>  {
        e.preventDefault();
        
        submenu.forEach((item) => item !== this ? item.closest('.has-child')?.classList.remove('expand') : null);

        if(e.target.closest('.has-child').classList !== 'expand')
        e.target.closest('.has-child').classList.toggle('expand');
    }

    submenu.forEach((menu) => menu.addEventListener('click', toggle));

    return (
        <>
            <aside className='site-off desktop-hide'>
                <div className='off-canvas'>
                    <div className='canvas-head'>
                        <div className="logo">
                            <Link to="/">
                                <img src={ logoAlpha } className='w-40' />
                            </Link>
                        </div>
                        <a href='' className='t-close flexcenter' onClick={ removeMobileMenu }>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </a>
                    </div>
                    <div className='departments'>
                        <div className="dpt-head">
                            <div className="main-text text-[#5a151e]">Todas as Categorias</div>
                            <div className="mini-text mobile-hide text-[#5a151e]">Total 1059 Products</div>
                            <a href="#" className="dpt-trigger mobile-hide text-[#5a151e]">
                                <Bars3Icon className='Bars3Icon'/>
                            </a>
                        </div>
                        <div className="dpt-menu">
                            <ul className="second-links">
                                {
                                    categories &&
                                    categories !== null &&
                                    categories !== undefined &&
                                    categories.categories.map((category:any) => {
                                        if(category.status == 1) {
                                            if(category.sub_categories.length > 0) {
                                                return (
                                                    <li key={category.id} className='has-child'>
                                                        <Link to={`/category/${category.slug}`}>
                                                            <div className="icon-large"><CubeIcon className='CubeIcon' /></div>
                                                            { category.name }
                                                            <div className="icon-small"><ChevronRightIcon className='ChevronRightIcon' /></div>
                                                        </Link>
                                                        <ul>
                                                            {
                                                                category.sub_categories.map((sub_category:any) => {
                                                                    if(sub_category.status == 1) {
                                                                        return (
                                                                            <li key={ sub_category.id }>
                                                                                <Link to={`/subcategory/${sub_category.slug}`}>{ sub_category.name }</Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li key={category.id}>
                                                        <Link to={`/category/${category.slug}`}>
                                                            <div className="icon-large"><CubeIcon className='CubeIcon' /></div>
                                                            { category.name }
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    
                    <div className='thetop-nav'>
                        <div className="left">
                            <ul className="flexitem main-links">
                                {/*<li><a href="#">Blog</a></li>*/}
                                {/*<li><a href="#">Produtos Novos</a></li>*/}
                                <li><Link to="/wishlist">Lista de Desejos</Link></li>
                            </ul>
                        </div>
                        <div className="right">
                            <ul className="flexitem main-links">
                                { user.access !== null && user.isAuthenticated ?
                                    <>
                                        <li><Link to="/dashboard">Minha Conta</Link></li>
                                        <li><Link to="/dashboard/payments">Meus Pedidos</Link></li>
                                    </>
                                    :
                                    <>
                                        <li><Link to="/signup">Registre-se</Link></li>
                                        <li><Link to="/signin">Entrar</Link></li>
                                    </>
                                }
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <header className='bg-[#efeeef]'>
                <div className="header-top mobile-hide">
                    <div className="container">
                        <div className="wrapper flexitem">
                            <div className="left">
                                <ul className="flexitem main-links">
                                    {/*<li><a href="#">Blog</a></li>*/}
                                    {/*<li><a href="#">Produtos Novos</a></li>*/}
                                    <li><a href="#">Lista de Desejos</a></li>
                                </ul>
                            </div>
                            <div className="right">
                                <ul className="flexitem main-links">
                                    { user.access !== null && user.isAuthenticated ?
                                        <>
                                            <li><Link to="/dashboard">Minha Conta</Link></li>
                                            <li><Link to="/dashboard/payments">Meus Pedidos</Link></li>
                                        </>
                                        :
                                        <>
                                            <li><Link to="/signup">Registre-se</Link></li>
                                            <li><Link to="/signin">Entrar</Link></li>
                                        </>
                                    }
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-nav">
                    <div className="container">
                        <div className="wrapper">
                            <div className="flexitem">
                                <a href="#" className="trigger desktop-hide" onClick={ showMobileMenu } ><Bars3Icon className='Bars3Icon'  /></a>
                                <div className="left flexitem">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={ logoAlpha } className='w-40' />
                                        </Link>
                                    </div>
                                    <nav className='mobile-hide'>
                                        <ul className='flexitem second-links'>
                                            {
                                                categories &&
                                                categories !== null &&
                                                categories !== undefined &&
                                                categories.categories.map((category:any) => {
                                                    if(category.show_in_header == 1) {
                                                        if(category.sub_categories.length > 0)
                                                        {
                                                            if(category.status == 1) {
                                                                return(
                                                                    <li key={category.id} className='has-child'>
                                                                        <Link to={`/category/${category.slug}`}>{category.name}
                                                                            <div className='icon-small'><ChevronDownIcon className='ChevronDownIcon' /></div>
                                                                        </Link>
                                                                        <div className="mega">
                                                                            <div className="container">
                                                                                <div className="wrapper">
                                                                                    <div className="flexcol">
                                                                                        <div className="row">
                                                                                            <h4>{category.name}</h4>
                                                                                            <ul>
                                                                                                {
                                                                                                    category.sub_categories.map((sub_category:any) => {
                                                                                                        if(sub_category.status == 1) {
                                                                                                            return (
                                                                                                                <li key={ sub_category.id }>
                                                                                                                    <Link to={`/subcategory/${sub_category.slug}`}>{ sub_category.name }</Link>
                                                                                                                </li>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flexcol products">
                                                                                        <div className="row">
                                                                                            <div className="media">
                                                                                                <div className="thumbnail object-cover-css">
                                                                                                    { /*<img src={ aparel } alt="" />*/ }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="text-content">
                                                                                                {
                                                                                                    /*<h4>Most wanted!</h4>
                                                                                                    <a href="#" className="primary-button">Order Now</a>*/
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        } else {
                                                            if(category.status == 1) {
                                                                return(
                                                                    <li key={category.id}>
                                                                        <Link to={`/category/${category.slug}`}>
                                                                            {category.name}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        }
                                                    }
                                                })                                                
                                            }
                                        </ul>
                                    </nav>
                                </div>
                                <div className="right">
                                    <ul className="flexitem second-links">
                                        { user.access !== null && user.isAuthenticated ?
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
                                                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden border border-[#6b5c5a] bg-gray-100 hover:border hover:border-[#794afa] transition-all duration-300">
                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        </span>
                                                    </Menu.Button>
                                                </div>
                                      
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                <Link
                                                                    to='/dashboard'
                                                                    className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    Meu Perfil
                                                                </Link>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    Support
                                                                </a>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    License
                                                                </a>
                                                                )}
                                                            </Menu.Item>
                                                            <form>
                                                                <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                    onClick={ logoutHandler }
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block w-full px-4 py-2 text-left text-sm'
                                                                    )}
                                                                    >
                                                                    Sair
                                                                    </button>
                                                                )}
                                                                </Menu.Item>
                                                            </form>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                            :
                                            <></>
                                        }
                                        <li className='mobile-hide'>
                                            <Link to='/wishlist'>
                                                <div className='icon-large'><HeartIcon className='HeartIcon' /></div>
                                                <div className='fly-item'><span className='item-number'>{ wishlist.total_items }</span></div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cart" className='iscart'>
                                                <div className="icon-large">
                                                    <ShoppingCartIcon className='ShoppingCartIcon' />
                                                    <div className='fly-item'><span className='item-number'>{ cart.total_items }</span></div>
                                                </div>
                                                <div className="icon-text">
                                                    <div className='mini-text'>Total</div>
                                                    <div className='cart-total'>R$ { (cart.sale_amount).toFixed(2) }</div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Alert />
        </>
    )
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 

const initialState = {
    orders: []
}

export const orderSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        getAllOrders: (state, action: PayloadAction<any | null>) => {
            state.orders = action.payload;
        },
        emptyOrders: (state, action: PayloadAction<any | null>) => {
            state.orders = [];
        }
    }
});

export default orderSlice.reducer;

export const { getAllOrders, emptyOrders } = orderSlice.actions;
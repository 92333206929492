import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 

const initialState = {
    items: [],
    amount: 0.00,
    discount: 0.00,
    sale_amount: 0.00,
    total_items: 0
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        setAdd_Item: (state, action: PayloadAction<any | null>) => {
            localStorage.setItem('cart', JSON.stringify(action.payload));
            state.items = JSON.parse(localStorage.getItem('cart') || '{}');
        },
        setAdd_Item_Fail: (state, action: PayloadAction<any | null>) => {
            state.items = [];
        },
        setGet_Items: (state, action: PayloadAction<any | null>) => {
            state.items = JSON.parse(localStorage.getItem('cart') || '{}');
        },
        setGet_Total: (state, action: PayloadAction<any | null>) => {
            state.amount = action.payload[0];
            state.sale_amount = action.payload[1];
        },
        setGet_Item_Total: (state, action: PayloadAction<any | null>) => {
            state.total_items = action.payload;
        },
        setUpdate_Item: (state, action: PayloadAction<any | null>) => {
            localStorage.setItem('cart', JSON.stringify(action.payload));
            state.items = JSON.parse(localStorage.getItem('cart') || '{}');
        },
        setRemove_item: (state, action: PayloadAction<any | null>) => {
            localStorage.setItem('cart', JSON.stringify(action.payload));
            state.items = JSON.parse(localStorage.getItem('cart') || '{}');
        },
        setDiscount: (state, action: PayloadAction<any | null>) => {
            state.discount = action.payload
        },
        setSaleAmount: (state, action: PayloadAction<any | null>) => {
            state.sale_amount = action.payload
        }
    }
})

export default cartSlice.reducer;

export const {
    setAdd_Item,
    setAdd_Item_Fail,
    setGet_Items,
    setGet_Total,
    setGet_Item_Total,
    setUpdate_Item,
    setRemove_item,
    setDiscount, 
    setSaleAmount
} = cartSlice.actions;
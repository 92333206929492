import axiosInstance from "./api";
import { AxiosResponse, AxiosError } from "axios";
import { Thunk } from "../redux/store";
import { 
    setFullActiveProducts,
    getFullActiveProductsFail,
    setLatestProductRequest, 
    setLatestProductRequestFail, 
    setHighlightsProductRequest, 
    setHighlightsProductRequestFail, 
    setProductsByCategory, 
    setProductsByCategoryFails,
    setProductsBySubCategory,
    setProductsBySubCategoryFails,
    setProductsBySubCategorySub,
    setProductsBySubCategorySubFails,
    setProductDetailsRequest,
    setProductDetailsRequestFail,
    setRelatedProductsRequest,
    setRelatedProductsRequestFail,
    setSearchProductsRequest,
    setSearchProductsRequestFail
} from "../redux/slice/products/productSlide";
import { setLoading } from "../redux/slice/commons/loadingSlice";

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const getFullActiveProducts = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get('/api/product/get-products?order=ASC', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setFullActiveProducts(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(getFullActiveProductsFail(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getLatestProducts = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get('/api/product/latest', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setLatestProductRequest(response.data.products));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setLatestProductRequestFail(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getHighlightsProducts = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get('/api/product/highlights', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setHighlightsProductRequest(response.data.products));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setHighlightsProductRequestFail(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getProductsByCategory = (data: any,page: any = 1): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get(`/api/product/product/category/${data.category}?page=${page}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setProductsByCategory(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setProductsByCategoryFails(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getProductsBySubCategory = (data: any, page: any = 1): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get(`/api/product/product/subcategory/${data.sub_category}?page=${page}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setProductsBySubCategory(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setProductsBySubCategoryFails(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getProductsBySubCategorySub = (data: any, page: any = 1): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get(`/api/product/product/subcategorysub/${data.sub_category_sub}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setProductsBySubCategorySub(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setProductsBySubCategorySubFails(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getProductsDetails = (data: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get(`/api/product/product/${data.product}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        dispatch(setProductDetailsRequest(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setProductDetailsRequestFail(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getRelatedProducts = (data: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get(`/api/product/product/related/${data.category_id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        dispatch(setRelatedProductsRequest(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setRelatedProductsRequestFail(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}

export const getSearchProducts = (data: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(true));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.post('/api/product/search/', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        dispatch(setSearchProductsRequest(response.data));
        dispatch(setLoading(false));

        return response;
    } catch(error: any) {
        dispatch(setSearchProductsRequestFail(error));
        dispatch(setLoading(false));

        return error as AxiosError;
    }
}
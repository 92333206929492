import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import imgDefault from '../../assets/global/default.jpg';

const CartItem = ({ item, count, update_item, remove_item, render, setRender, setAlert }:any) => {

    const [ formData, setFormData ] = useState({
        item_count: 1
    })

    const { item_count } = formData;

    useEffect(() => {
        if(count)
            setFormData({ ...formData, item_count: count })
    }, [count]);

    useEffect(() => {
        try {
            if(item.product.quantity >= item_count) {
                update_item(item, item_count);
            } else {
                setAlert('red','Stock insuficiente.')
            }
            setRender(!render);
        } catch(err) {

        }
    }, [item_count])

    const onChange = (e:any) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const incrementQuantity = () => {
        setFormData({ ...formData, item_count: item_count + 1 })
    }

    const decrementQuantity = () => {
        if(item_count > 1) {
            setFormData({ ...formData, item_count: item_count - 1 })
        }
    }

    const ShowPrice = () => {

        if(Number(item.product.sale_price > 0)) {
            return(
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.sale_price).toFixed(2) }</p>
            )
        } else {
            return (
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.regular_price).toFixed(2) }</p>
            )
        }
    }

    const ShowTotal = () => {
        if(Number(item.product.sale_price > 0)) {
            return(
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.sale_price * item.count).toFixed(2) }</p>
            )
        } else {
            return (
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.regular_price * item.count).toFixed(2) }</p>
            )
        }
    }

    const removeItemHandler = async () => {
        await remove_item(item);
        setRender(!render);
    };

    return (
        <div className="border border-[#8b716f] mb-2 rounded-md p-2 flex flex-wrap">
            <div className="flex justify-center items-center p-1 w-1/6 sm:w-1/12">
                <button type='button' onClick={ removeItemHandler }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </div>
            <div className="w-20 h-20 rounded-md overflow-hidden">
                <img src={ imgDefault } />
            </div>
            <div className='px-2 w-6/12 flex flex-col justify-center md:w-4/12 lg:w-[35%] xl:w-5/12'>
                <p className='text-sm leading-4'><Link to={`/product/${item.product.slug}`}>{ item.product.name }</Link></p>
                <p className='text-xs mt-2'>SKU: {item.product.sku}</p>
            </div>
            <div className='w-full p-2 flex items-center justify-center md:justify-end sm:w-1/4 md:w-[15%] lg:w-[14%]'>
                {
                    ShowPrice()
                }
            </div>
            <div className='w-full p-2 flex justify-center items-center md:w-fit'>
                <form className="flex items-center border border-[#8b716f] rounded-md overflow-hidden w-24">
                    <button type="button" className="bg-gray-300 font-bold h-full py-1 w-12 text-[#8b716f] cursor-pointer outline-none" onClick={ decrementQuantity }>-</button>
                    <input name='item_count' className="w-full text-center py-1 text-sm outline-none" type="number" value={item_count} onChange={(e) => onChange(e)} min={0} />
                    <button type="button" className="bg-gray-300 font-bold h-full py-1 w-12 text-[#8b716f] cursor-pointer outline-none" onClick={ incrementQuantity }>+</button>
                </form>
            </div>
            <div className='w-full p-2 flex justify-center md:justify-end items-center md:w-[15%] lg:w-[14%]'>
                {
                    ShowTotal()
                }
            </div>
        </div>
    )
}

export default CartItem;
import { Bars3Icon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, CubeIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Beauty from '../../assets/menu/menu_bg1.jpg'

const Departments = () => {

    const [ active, setActive ] = useState(false);
    const [ searchValue, setSearchValue ] = useState('');
    const navigate = useNavigate();

    const showDepartmentMenu = (e: any) => {
        e.preventDefault();
        if(active) {
            setActive(false);
        } else {
            setActive(true);
        }
    }

    const handleSearchInput = (e:any) => {
        setSearchValue(e.target.value);
    }

    const searchButton = (e:any) => {
        e.preventDefault();
        navigate('/product/search/'+searchValue);
    }

    const { categories } = useSelector((state:any) => state);
    const { products } = useSelector((state:any) => state);

    const ShowTotalProducts = () => {
        if(products.products_active.total_products_acive && products.products_active.total_products_acive !== null && products.products_active.total_products_acive !== undefined) { 
            return (
                <div className="mini-text mobile-hide text-[#5a151e]">Total { products.products_active.total_products_acive } Produtos</div>
            )
        } else {
            <div className="mini-text mobile-hide text-[#5a151e]">Total 0 Produtos</div>
        }
    }

    return (
        <div className="header-main mobile-hide">
            <div className="container">
                <div className="wrapper flexitem">
                    <div className="left">
                        <div className="dpt-cat">
                            <div className="dpt-head">
                                <div className="main-text text-[#5a151e]">Todas as Categorias</div>
                                {
                                    ShowTotalProducts()
                                }
                                <a href="#" className="dpt-trigger mobile-hide text-[#5a151e]" onClick={ showDepartmentMenu }>
                                    <Bars3Icon className='Bars3Icon'/>
                                </a>
                            </div>
                            <div className={`dpt-menu ${ active ? 'showdpt' : ''}`}>
                                <ul className="second-links">
                                    {
                                        categories &&
                                        categories !== null &&
                                        categories !== undefined &&
                                        categories.categories.map((category:any) => {
                                            if(category.status == 1) {
                                                if(category.sub_categories.length > 0) {
                                                    return (
                                                        <li key={category.id} className='has-child'>
                                                            <Link to={`/category/${category.slug}`}>
                                                                <div className="icon-large"><CubeIcon className='CubeIcon' /></div>
                                                                { category.name }
                                                                <div className="icon-small"><ChevronRightIcon className='ChevronRightIcon' /></div>
                                                            </Link>
                                                            <ul>
                                                                {
                                                                    category.sub_categories.map((sub_category:any) => {
                                                                        if(sub_category.status == 1) {
                                                                            return (
                                                                                <li key={ sub_category.id }>
                                                                                    <Link to={`/subcategory/${sub_category.slug}`}>{ sub_category.name }</Link>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </ul>
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li key={category.id}>
                                                            <Link to={`/category/${category.slug}`}>
                                                                <div className="icon-large"><CubeIcon className='CubeIcon' /></div>
                                                                { category.name }
                                                            </Link>
                                                        </li>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="search-box">
                            <form action="" className="search" onSubmit={searchButton}>
                                <span className="icon-large"><MagnifyingGlassIcon className='MagnifyingGlassIcon' /></span>
                                <input type="search" placeholder='Pesquisar productos...' onChange={handleSearchInput} />
                                <button type="submit">Pesquisar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Departments;
import axiosInstance from "./api";
import { AxiosResponse, AxiosError } from "axios";
import { Thunk } from "../redux/store";
import { toast } from 'react-toastify';
import { addWishlist, resetWishList } from "../redux/slice/products/wishlistSlice";

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const addWishlistitem = (productData: any, token: string, user_id: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {
    
    try {
        await csrf();

        let product_id = productData.id;

        const body = JSON.stringify({
            user_id,
            product_id
        });

        const response: AxiosResponse = await axiosInstance.post('/api/wishlist/add-item',body , {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        dispatch(addWishlist(productData));

        toast.success("Produto adicionado com sucesso.", {
            theme: "colored",
        });

        return response;
    } catch(error: any) {
        //dispatch(setCategoryRequestFail(error));

        return error as AxiosError;
    }
}

export const getWishlistProducts = (token: string, user_id: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {
    try {
        await csrf();

        const body = JSON.stringify({
            user_id
        });

        const response: AxiosResponse = await axiosInstance.post('/api/wishlist/get-products',body , {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });

        dispatch(resetWishList(null));

        for(let i = 0; i < response.data.wishlist.length; i++) {
            dispatch(addWishlist(response.data.wishlist[i]));
        }
        
        return response;
    } catch(error: any) {
        //dispatch(setCategoryRequestFail(error));

        return error as AxiosError;
    }
}

export const removeItemWishlist = (product_id:any, user_id:any, token: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {
    try {
        await csrf();

        const body = JSON.stringify({
            product_id,
            user_id
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: body
        };

        const response: AxiosResponse = await axiosInstance.delete('/api/wishlist/remove-item', config);
        
        toast.success("Produto eliminado com sucesso.", {
            theme: "colored",
        });

        return response;
    } catch(error: any) {
        //dispatch(setCategoryRequestFail(error));

        return error as AxiosError;
    }
}
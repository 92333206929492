import Layout from '../hocs/Layout';
import Departments from '../components/home/deparments';
import SliderMain from '../components/home/slider';
import InfoSmall from '../components/home/infosmall';
import BigCards from '../components/home/bigcards';
import ShipInfo from '../components/home/shipinfo';
import NewProductsSection from '../components/home/newsection';
import InfoCards from '../components/home/infocards';
import Highlights from '../components/home/highlights';
import Newsletter from '../components/home/newsletter';

const Home = () => {
    return ( 
        <Layout>
            <Departments />
            <SliderMain />
            <InfoSmall />
            <BigCards />
            <NewProductsSection />
            <ShipInfo />
            <InfoCards />
            <Highlights />
            <Newsletter />
        </Layout>
    )
}

export default Home
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    categories: []
}

export const categorySlice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {
        setCategoryRequest:(state, action: PayloadAction<any | null>) => {
            state.categories = action.payload;
        },
        setCategoryRequestFail:(state, action: PayloadAction<any | null>) => {
            state.categories = [];
        }
    }
});

export default categorySlice.reducer;

export const { setCategoryRequest, setCategoryRequestFail } = categorySlice.actions;
import { ReactNode, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch } from '../hooks/redux/hooks';
import { getCategories } from '../service/categoryService';
import { getLatestProducts, getHighlightsProducts, getFullActiveProducts } from '../service/productService';
import { getItemsCart, getTotalCart, getItemTotalCart } from '../service/cartService';
import { check_authenticated, getLoadUser } from '../service/userService';
import { getWishlistProducts } from '../service/wishlistService';
import { useSelector } from 'react-redux';
import { setLoading } from '../redux/slice/commons/loadingSlice';

import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';


import 'react-toastify/dist/ReactToastify.css';

type Props = {
    children: ReactNode
}

const Layout = ({children}: Props) => {

    const { user } = useSelector((state:any) => state);

    const categoryDispatch = useAppDispatch();
    const productDispatch = useAppDispatch();
    const cartDispatch = useAppDispatch();
    const userDispatch = useAppDispatch();
    const wishlistDispatch = useAppDispatch();
    const loadingDispatch = useAppDispatch();

    useEffect(() => {
        categoryDispatch(getCategories());
    }, []);

    useEffect(() => {
        productDispatch(getLatestProducts());
        productDispatch(getHighlightsProducts());
        productDispatch(getFullActiveProducts());
    }, []);

    useEffect(() => {
        cartDispatch(getItemsCart());
        cartDispatch(getTotalCart());
        cartDispatch(getItemTotalCart());
    }, [])

    useEffect(() => {
        if(user.isAuthenticated) {
            wishlistDispatch(getWishlistProducts(user.access, user.user.user.id));
        }
    }, [user.access])

    useEffect(() => {
        userDispatch(check_authenticated(user.access));
        userDispatch(getLoadUser(user.access))
    }, [user.access]);

    return (
        <div className='site'>
            <Navbar />
            <ToastContainer autoClose={5000} />
            {children}
            <Footer />
        </div>
    )
}

export default Layout;
import axiosInstance from "./api";
import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const newsletterRegister = async (data: any) => {
    try {
        await csrf();

        const response: AxiosResponse = await axiosInstance.post('/api/newsletter/register', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        switch(response.status) {
            case 201:
                toast.success("Você se registrou com sucesso em nossa newsletter.", {
                    theme: "colored"
                });
                break;
            case 500:
                toast.error("Falha ao registrar newsletter.", {
                    theme: "colored"
                });
                break;
        }
        
    } catch(error: any) {

        const errorResponse = error.response.data.error

        if(errorResponse !== null || errorResponse !== 'undefined') {
            Object.keys(errorResponse).map(key => {
                toast.error(`Ocorreu um erro, verifique. ${errorResponse[key]}`, {
                    theme: "colored"
                });
            })
        } else {
            toast.error("Ocorreu um erro. Por favor tente novamente mais tarde.", {
                theme: "colored"
            });
        }
    }
}
import Layout from "../hocs/Layout"
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux/hooks';
import { Fragment, useState, useEffect } from 'react';
import { getSearchProducts } from "../service/productService";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import ProductCard from "../components/common/productcard";
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid';

const sortOptions: any = [
    { name: 'Mais popular', href: '#', current: true },
    { name: 'Melhor classificação', href: '#', current: false },
    { name: 'O mais novo', href: '#', current: false },
    { name: 'Preço: baixo a alto', href: '#', current: false },
    { name: 'Preço: alto a baixo', href: '#', current: false }
];

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ');
}


const SearchProduct = () => {

    const params = useParams();
    const search_value = params.value;
    const searchProductDispatch = useAppDispatch();
    const { products } = useSelector((state:any) => state);

    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0);
        searchProductDispatch(getSearchProducts({
            search_query: search_value
        }))
    }, [search_value]);

    return (
        <Layout>
            <div className="container">
                <div className="my-6 flex items-center flex-wrap text-[#8b716f]">
                    <span className="text-sm"><Link to='/'>Home</Link></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm">Pesquisar</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm text-gray-400">{ search_value }</span>
                </div>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex items-baseline justify-between border-b border-[#8b716f] pb-2 pt-6">
                        <h1 className="text-xl font-bold tracking-tight text-[#8b716f]">Pesquisa de Produtos</h1>
                        <div className="flex items-center">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        Ordem
                                        <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {
                                                sortOptions.map((option:any) => (
                                                    <Menu.Item key={option.name}>
                                                        {
                                                            ({ active }) => (
                                                                <a href={ option.href } className={classNames(option.current ? 'font-medium text-gray-900' : 'text-gray-500', active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm')}>
                                                                    { option.name }
                                                                </a>
                                                            )
                                                        }
                                                    </Menu.Item>
                                                ))
                                            }
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            <button type="button" className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden" onClick={() => setMobileFiltersOpen(true)}>
                                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <section aria-labelledby="products-heading" className="pb-24 pt-6" >
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            <form className="hidden lg:block">
                                <ul role="list" className="space-y-4 pb-6 text-sm font-medium text-[#8b716f]"></ul>
                            </form>
                            <div className="lg:col-span-3 flex flex-col sm:flex-row sm:flex-wrap">
                                { 
                                    products.filtered !== null && 
                                    products.filtered.products &&
                                    products.filtered.products !== null &&
                                    products.filtered.products !== undefined && 
                                    products.filtered.products.map((product:any) => {
                                        if(product.status == 1) {
                                            return (
                                                <div key={product.id} className='mt-4 sm:w-1/2 md:w-1/3 lg:w-1/4'>
                                                    <ProductCard id={product.id} name={product.name} slug={product.slug} image={process.env.REACT_APP_API_URL + '/' +product.image} price={product.regular_price} sale_price={ product.sale_price } product={product} />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

export default SearchProduct;
const ProgressBar = () => {
    return (
        <>
            <div>
                <div className="bg-gray-300 w-full h-2 rounded-md overflow-hidden">
                    <div className="bg-blue-900 w-20 h-2 transition-all ease-in-out duration-300"></div>
                </div>
                <div className="text-sm mt-3">
                    <p>Ordem Creada el 12 de Julio 2023 </p>
                </div>
            </div>
        </>
    )
}

export default ProgressBar;
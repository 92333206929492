import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import ProductCard from '../common/productcard';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const NewProductsSection = () => {

    const { products } = useSelector((state:any) => state);
    const { loading } = useSelector((state:any) => state);

    return (
        <div className="container">
            <div className='w-full flex flex-col justify-center items-center mb-5 mt-5'>
                <p className='text-center md:text-xl pb-2'>Confira nossos lançamentos</p>
                <hr className='w-4/5 md:w-3/6 border md:border-2 border-[#8b716f]' />
            </div>
            <div>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={40}
                    slidesPerView={4}
                    navigation={true}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                      }}
                >
                    { loading.globalLoading && <Skeleton count={4} />}
                    {
                        products.products.data &&
                        products.products.data !== null &&
                        products.products.data !== undefined && 
                        products.products.data.map((product:any) => {
                            if(product.status == 1) {
                                return (
                                    <SwiperSlide key={product.id}>
                                        <ProductCard id={product.id} name={product.name} slug={product.slug} image={process.env.REACT_APP_API_URL + '/' +product.image} price={product.regular_price} sale_price={ product.sale_price } product={product} />
                                    </SwiperSlide>
                                )
                            }
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default NewProductsSection;
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Importando os reducers
import userReducer from './slice/users/userSlice';
import alertReducer from './slice/commons/alertSlice';
import categoryReducer from './slice/products/categorySlice';
import productReducer from './slice/products/productSlide';
import cartReducer from './slice/products/cartSlice';
import wishlistReducter from './slice/products/wishlistSlice';
import orderReducer from './slice/orders/orderSlice';
import loadingReducer from './slice/commons/loadingSlice';

const persistUserConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['access']
}

export const store = configureStore({
    reducer: {
        user: persistReducer<ReturnType<typeof userReducer>>(persistUserConfig, userReducer),
        alert: alertReducer,
        categories: categoryReducer,
        products: productReducer,
        cart: cartReducer,
        wishlist: wishlistReducter,
        orders: orderReducer,
        loading: loadingReducer
    },
    middleware: (defaultMiddleware) => defaultMiddleware({
        serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type Thunk = ThunkAction<Promise<unknown>, RootState, unknown, Action<unknown>>;

export const persistor = persistStore(store); 
import { useSelector } from 'react-redux';
import ProductCard from '../common/productcard';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Highlights = () => {

    const { products } = useSelector((state:any) => state);
    const { loading } = useSelector((state:any) => state);

    return (
        <div className="container">
            <div className='w-full flex flex-col justify-center items-center mb-5 mt-5'>
                <p className='text-center md:text-xl pb-2'>Confira nossos destaques</p>
                <hr className='w-4/5 md:w-3/6 border md:border-2 border-[#8b716f]' />
            </div>
            { loading.globalLoading && <Skeleton count={4} />}
            <div className="flex flex-col sm:flex-row sm:flex-wrap">    
                {
                    products.highlights &&
                    products.highlights !== null &&
                    products.highlights !== undefined && 
                    products.highlights.map((product:any) => {
                        if(product.status == 1) {
                            return (
                                <div key={product.id} className='mt-4 sm:w-1/2 md:w-1/3 lg:w-1/4'>
                                    <ProductCard id={product.id} name={product.name} slug={product.slug} image={process.env.REACT_APP_API_URL + '/' +product.image} price={product.regular_price} sale_price={ product.sale_price } product={product} />
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export default Highlights;
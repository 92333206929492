import Layout from '../../hocs/Layout';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/hooks';
import { active } from '../../service/userService';
import { Navigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import { useSelector } from 'react-redux';

const ActivatePage = () => {

    const params = useParams();
    const [ searchParams ] = useSearchParams();
    const userDispatch = useAppDispatch();
    const { user } = useSelector((state:any) => state);

    const [ activated, setActivated ] = useState(false);

    const activate_account = () => {
        const uid = params.uid;
        const token = params.token;
        const expires = searchParams.get('expires');
        const signature = searchParams.get('signature');

        userDispatch(active({
            'uid': uid,
            'token': token,
            'expires': expires,
            'signature': signature
        }));

        setActivated(true);
    }

    if(activated && !user.loading) {
        return <Navigate to='/' />
    }

    return (
        <Layout>
            <main className='grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8'>
                <div className='text-center'>
                    <p className='text-base font-semibold text-[#8b716f]'>Confirme seu cadastro para ativar sua conta</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-600 sm:text-5xl">Ativação de conta</h1>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        {user.loading ?
                            <button className="rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 text-white transition-all duration-300 focus-visible:outline-offset-2 bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f]">
                                <Oval color="#fff" width={20} height={20} />
                            </button>:
                            <button onClick={ activate_account } className="rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 text-white transition-all duration-300 focus-visible:outline-offset-2 bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f]">
                                Ativar conta
                            </button>
                        }
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default ActivatePage
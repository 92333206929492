import imgDefault from '../../assets/global/default.jpg';
import { Link } from 'react-router-dom';

const WishlistItem = ({ item, remove_item }: any) => {
    
    const removeItemHandler = async () => {
        await remove_item(item.product.id);
    }

    const ShowPrice = () => {

        if(Number(item.product.sale_price > 0)) {
            return(
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.sale_price).toFixed(2) }</p>
            )
        } else {
            return (
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.regular_price).toFixed(2) }</p>
            )
        }
    }

    return (
        <div className="border border-[#8b716f] mb-2 rounded-md p-2 flex flex-wrap">
            <div className="flex justify-center items-center p-1 w-1/6 sm:w-1/12">
                <button type='button' onClick={ removeItemHandler }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
            </div>
            <div className="w-20 h-20 rounded-md overflow-hidden">
                <img src={ imgDefault } />
            </div>
            <div className='px-2 w-6/12 flex flex-col justify-center md:w-7/12 lg:w-[35%] xl:w-8/12'>
                <p className='text-sm leading-4'><Link to={`/product/${item.product.slug}`}>{ item.product.name }</Link></p>
                <p className='text-xs mt-2'>SKU: {item.product.sku}</p>
            </div>
            <div className='w-full p-2 flex items-center justify-center md:justify-end sm:w-1/4 md:w-[15%] lg:w-[14%]'>
                {
                    ShowPrice()
                }
            </div>
        </div>
    )
}

export default WishlistItem;
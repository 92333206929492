export const Footer = () => {
    return (
        <>
            <footer className="w-full border-t-4 border-b-4 border-[#8b716f] bg-[#cccccc] flex flex-col justify-center items-center pt-4 pb-6 sm:flex-row sm:px-4 lg:p-8">
                <div className="w-full flex flex-col items-center sm:h-52 sm:mt-4">
                    <h1 className="text-center text-base font-['Rubik']">Institucional</h1>
                    <hr className="w-6/12 border border-[#8b716f]" />
                    <ul className="self-start mt-2 ml-2 text-sm w-full text-[#8b716f]">
                        <li className="py-1">
                            <a>
                                Como comprar
                            </a>
                        </li>
                        <li className="py-1">
                            <a>
                                Envio
                            </a>
                        </li>
                        <li className="py-1">
                            <a>
                                Política da Empresa
                            </a>
                        </li>
                        <li className="py-1">
                            <a>
                                Contato
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="w-full flex flex-col items-center mt-4 sm:h-52">
                    <h1 className="text-center text-base font-['Rubik']">Atendimento</h1>
                    <hr className="w-6/12 border border-[#8b716f]" />
                    <ul className="self-start mt-2 ml-2 text-sm w-full">
                        <li className="py-1">
                            <a href="#" className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-[#8b716f]">
                                    <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                                </svg>
                                <p className="text-[#8b716f] text-sm ml-2">+55 45 8575875</p>
                            </a>
                        </li>
                        <li className="py-1">
                            <a href="#" className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="w-4 h-4 text-[#8b716f]">
                                    <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                        C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                        c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                        c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                        c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                        c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                        c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                        c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                        c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z">
                                    </path>
                                </svg>
                                <p className="text-[#8b716f] text-sm ml-2">+55 45 8575875</p>
                            </a>
                        </li>
                        <li className="py-1">
                            <a href="#" className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-[#8b716f]">
                                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                </svg>
                                <p className="text-[#8b716f] text-sm ml-2">contato@deisepeliculas.com.br</p>
                            </a>
                        </li>
                        <li className="py-1 border-[#8b716f] border-t mt-2">
                            <p className="text-[#8b716f] text-sm ml-1">Segunda à sexta-feiradas 09:00 às 12:00 e 14:00 às 17:00</p>
                        </li>
                    </ul>
                </div>
                <div className="w-full flex flex-col items-center mt-4 sm:h-52">
                    <h1 className="text-center text-base font-['Rubik']">Redes Sociais</h1>
                    <hr className="w-6/12 border border-[#8b716f]" />
                    <ul className="self-center mt-2 ml-2 text-sm w-1/2 flex justify-around items-center">
                        <li className="py-1">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.124 96.123" fill="currentColor" className="w-6 h-6 text-[#8b716f]">
                                    <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803   c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654   c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246   c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"></path>
                                </svg>
                                <p className="text-[#8b716f] text-sm ml-1 hidden">Facebook</p>
                            </a>
                        </li>
                        <li className="py-1">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.063 169.063"fill="currentColor" className="w-6 h-6 text-[#8b716f]">
                                    <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
                    c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
                    c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
                    c17.455,0,31.656,14.201,31.656,31.655V122.407z"></path>
                                    <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
                    C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
                    c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"></path>
                                    <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
                    c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
                        C135.661,29.421,132.821,28.251,129.921,28.251z"></path></svg>
                                <p className="text-[#8b716f] text-sm ml-1 hidden">Instagram</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
            <div className="bg-[#cccccc] text-xs pt-3 pb-3 text-[#8b716f]">
                <p className="text-center">Deise Amelia Smith - CNPJ: 00.000.000/0001-02 © Todos os direitos reservados. 2023</p>
                <p className="text-center mt-2">Desenvolvido por: Seventech S.A.</p>
            </div>
        </>
    )
}
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'
import { alerModel } from '../models/alert-models';

const Alert = () => {

    const { alert } = useSelector((state:any) => state);

    const displayAlert = () => {
        if(alert.alertType !== null) {
            switch(alert.alertType) {
                case 'red':
                    return (
                        <div className={`bg-red-50 rounded-md p-4 mx-5 mb-5 transition-all duration-300`}>
                            <div className='flex'>
                                <div className='flex-shrink-0'>
                                    <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' /> 
                                </div>
                                <div className='ml-3'>
                                    <p className='text-sm font-medium text-red-800'>
                                        {alert.message}
                                    </p> 
                                </div>
                            </div>
                        </div>
                    )
                case 'green':
                    return (
                        <div className={`bg-green-50 rounded-md p-4 mx-5 mb-5 transition-all duration-300`}>
                            <div className='flex'>
                                <div className='flex-shrink-0'>
                                    <CheckCircleIcon className='h-5 w-5 text-green-400' aria-hidden='true' /> 
                                </div>
                                <div className='ml-3'>
                                    <p className='text-sm font-medium text-green-800'>
                                        {alert.message}
                                    </p> 
                                </div>
                            </div>
                        </div>
                    )
            }
            
        } else {
            <></>
        }
    }

    return (
        <>
            { displayAlert() }
        </>
    )
}

export default Alert;
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Link, useNavigate } from "react-router-dom";
import DashboardLinks from "../components/dashboard/DashboardLinks";
import OrderItem from '../components/payments/OrderItem';
import ProgressBar from '../components/common/progress';
import { logout } from "../service/userService";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../hooks/redux/hooks";
import { check_authenticated } from '../service/userService';
import { getLoadUser } from '../service/userService';
import { getOrders } from '../service/orderService';
import moment from 'moment';

import logoAlpha from '../assets/global/logoalpha.png';

const DashboardPayments = () => {

    const { user } = useSelector((state:any) => state);
    const { orders } = useSelector((state:any) => state);

    const userDispatch = useAppDispatch();

    const navigate = useNavigate();

    const [ sidebarOpen, setSidebarOpen ] = useState(false);
    const [ check, setCheck ] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        userDispatch(check_authenticated(user.access));
    }, [user.access]);

    useEffect(() => {
        const fetchData = async () => {
            await userDispatch(getLoadUser(user.access));
            setCheck(true);
        }

        fetchData();
    }, []);

    useEffect(() => {

        if(check) {
            if(!user.isAuthenticated) {
                navigate('/');
            }
        }

    }, [check]);

    useEffect(() => {
        if(user.user && user.user !== null)
        {
            userDispatch(getOrders(user.user.user.id, user.access));
        }
    }, [user.user]);

    function classNames(...classes:any) {
        return classes.filter(Boolean).join(' ')
    }

    const logoutHandler = (e: any) => {
        e.preventDefault();
        userDispatch(logout());
        navigate('/');
    }

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                        <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
                            <div className="relative flex-1 flex flex-col max-w-[75%] w-full pt-5 pb-4 bg-white">
                                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setSidebarOpen(false)} >
                                            <span className="sr-only">Close sidebar</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <Link to="/" className="flex justify-center items-center cursor-pointer">
                                        <img className="w-3/4" src={ logoAlpha } />
                                    </Link>
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2 space-y-1">
                                        <DashboardLinks />
                                    </nav>
                                </div>
                            </div>           
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>
                {/* Static sidebar for desktop 64 */}
                <div className="hidden md:flex md:w-56 md:flex-col md:fixed md:inset-y-0">
                    <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4 justify-center">
                            <Link to="/" className="flex justify-center items-center cursor-pointer">
                                <img className="w-3/4" src={ logoAlpha } />
                            </Link>
                        </div>
                        <div className="mt-5 flex-grow flex flex-col">
                            <nav className="flex-1 px-2 pb-4 space-y-1">
                                <DashboardLinks />
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="md:pl-56 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
                        <button type="button" className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                            </svg>
                        </button>
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="flex-1 flex"></div>
                            <div className="ml-4 flex items-center md:ml-6">
                                <button type="button" className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span className="sr-only">View notifications</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                    </svg>
                                </button>
                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">Open user menu</span>
                                            <span className="inline-block h-8 w-8 rounded-full overflow-hidden border border-[#6b5c5a] bg-gray-100 hover:border hover:border-[#794afa] transition-all duration-300">
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            </span>
                                        </Menu.Button>
                                    </div>
                                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <Link
                                                        to='/dashboard'
                                                        className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Meu Perfil
                                                    </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Support
                                                    </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        License
                                                    </a>
                                                    )}
                                                </Menu.Item>
                                                <form>
                                                    <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                        onClick={ logoutHandler }
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                        >
                                                        Sair
                                                        </button>
                                                    )}
                                                    </Menu.Item>
                                                </form>
                                            </div>
                                        </Menu.Items>             
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <main className="flex-1">
                        <div className='p-3 mb-4'>
                            <p className='font-bold text-lg text-[#6b5c5a] mb-1'>Histórico de pedidos</p>
                            <p className='text-gray-400 text-sm leading-4'>Verifique o status de pedidos recentes, gerencie devoluções e descubra produtos semelhantes.</p>
                        </div>
                        {
                            orders.orders !== null && 
                            orders.orders &&
                            orders.orders.orders &&
                            orders.orders.orders !== null &&
                            orders.orders.orders !== undefined &&
                            orders.orders.orders.map((order: any) => {
                                return (
                                    <div key={ order.id } className='border !mb-10 sm:max-w-xl sm:m-auto sm:rounded-md md:max-w-lg lg:max-w-3xl xl:max-w-4xl'>
                                        <div className='p-3 flex justify-around border'>
                                            <div className='flex flex-col items-center text-sm'>
                                                <p className='font-bold text-[#6b5c5a]'>N Pedido</p>
                                                <p className='text-gray-400'>{ order.id }</p>
                                            </div>
                                            <div className='hidden flex-col items-center text-sm sm:flex'>
                                                <p className='font-bold text-[#6b5c5a]'>Data</p>
                                                <p className='text-gray-400'>{ moment(order.created_at).format('D MMM YY') }</p>
                                            </div>
                                            <div className='flex flex-col items-center text-sm'>
                                                <p className='font-bold text-[#6b5c5a]'>Valor Total</p>
                                                <p>R$ { (order.total) }</p>
                                            </div>
                                        </div>
                                        <div className='p-3'>
                                            {
                                                order.order_items.map((item: any) => {
                                                    return(
                                                        <OrderItem key={ item.product_id } title={ item.product.name } price={ item.price } description={ item.product.description } />
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='p-3'>
                                            <ProgressBar />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                        
                    </main>
                </div>
            </div>
            <ToastContainer autoClose={5000} />
        </>
    )
}

export default DashboardPayments;
import Pedras from '../../assets/global/pedras.jpg';
import Info2 from '../../assets/global/Info2.jpg';
import Info3 from '../../assets/global/Info3.jpg';

const InfoCards = () => {
    return (
        <div className="container flex justify-between items-center flex-col sm:flex-row">
            <div className="w-full rounded-md mt-4 h-36 overflow-hidden shadow-md sm:mt-8">
                <a className="relative group h-full w-full block bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${ Pedras })` }} href="#">
                    <div className='w-full h-full' style={{ backgroundColor: 'rgba(0,0,0,.5)' }}></div>
                    <h1 className='absolute text-sm top-3 left-3 text-white font-thin'>Confira nossos modelos de pedras para unhas.</h1>
                    <button className='absolute bg-[#8b716f] bottom-5 right-4 py-2 px-3 text-xs text-white uppercase rounded-md border border-[#8b716f] transition-all duration-300 hover:bg-white hover:text-[#8b716f]'>
                        Saiba Mais
                    </button>
                </a>
            </div>
            <div className="w-full rounded-md mt-4 h-36 overflow-hidden shadow-md sm:mx-4 sm:mt-8">
                <a className="relative group h-full w-full block bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${ Info2 })` }} href="#">
                    <div className='w-full h-full' style={{ backgroundColor: 'rgba(0,0,0,.5)' }}></div>
                    <h1 className='absolute text-sm top-3 left-3 text-white font-thin'>Linha de esmaltes totalmente renovada.</h1>
                    <button className='absolute bg-[#8b716f] bottom-5 left-4 py-2 px-3 text-xs text-white uppercase rounded-md border border-[#8b716f] transition-all duration-300 hover:bg-white hover:text-[#8b716f]'>
                        Saiba Mais
                    </button>
                </a>
            </div>
            <div className="w-full rounded-md mt-4 h-36 overflow-hidden shadow-md sm:mt-8">
                <a className="relative group h-full w-full block bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${ Info3 })` }} href="#">
                    <div className='w-full h-full' style={{ backgroundColor: 'rgba(0,0,0,.5)' }}></div>
                    <h1 className='absolute text-sm top-3 left-3 text-white font-thin'>Acessórios de alta qualidade.</h1>
                    <button className='absolute bg-[#8b716f] bottom-5 right-4 py-2 px-3 text-xs text-white uppercase rounded-md border border-[#8b716f] transition-all duration-300 hover:bg-white hover:text-[#8b716f]'>
                        Saiba Mais
                    </button>
                </a>
            </div>
        </div>
    )
}

export default InfoCards;
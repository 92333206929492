import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import imgDefault from '../../assets/global/default.jpg';

const CheckoutItem = ({item, count}: any) => {

    const [ formData, setFormData ] = useState({
        item_count: 1
    })

    const { item_count } = formData;

    useEffect(() => {
        if(count)
            setFormData({ ...formData, item_count: count })
    }, [count]);

    const ShowPrice = () => {

        if(Number(item.product.sale_price > 0)) {
            return(
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.sale_price).toFixed(2) }</p>
            )
        } else {
            return (
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.regular_price).toFixed(2) }</p>
            )
        }
    }

    const ShowTotal = () => {
        if(Number(item.product.sale_price > 0)) {
            return(
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.sale_price * item.count).toFixed(2) }</p>
            )
        } else {
            return (
                <p className="text-base font-bold text-gray-900">R$ { Number(item.product.regular_price * item.count).toFixed(2) }</p>
            )
        }
    }

    return (
        <div className="border border-[#8b716f] mb-2 rounded-md p-2 flex flex-wrap">
            <div className="w-20 h-20 rounded-md overflow-hidden">
                <img src={ imgDefault } />
            </div>
            <div className='px-2 w-7/12 flex flex-col justify-center md:w-5/12 lg:md:w-5/12'>
                <p className='text-sm leading-4'><Link to={`/product/${item.product.slug}`}>{ item.product.name }</Link></p>
                <p className='text-xs mt-2'>SKU: {item.product.sku}</p>
            </div>
            <div className='w-full p-2 flex items-center justify-center md:justify-end sm:w-1/4 md:w-[16%] lg:w-[18%]'>
                {
                    ShowPrice()
                }
            </div>
            <div className='w-full p-2 flex justify-center items-center md:w-[15%] lg:w-[13%] xl:w-[17%]'>
                { item_count }
            </div>
            <div className='w-full p-2 flex justify-center md:justify-end items-center md:w-[15%] lg:w-[14%] xl:w-[14%]'>
                {
                    ShowTotal()
                }
            </div>
        </div>
    )
}

export default CheckoutItem;
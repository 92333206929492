import Layout from "../hocs/Layout"
import { useAppDispatch } from '../hooks/redux/hooks';
import { getItemsCart, getTotalCart, getItemTotalCart, removeItemCart, updateItemCart } from "../service/cartService";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import CartItem from "../components/cart/cartitem";
import { Link } from "react-router-dom";
import { setAlert, removeAlert } from "../redux/slice/commons/alertSlice";
import { toast } from 'react-toastify';

const Cart = () => {

    const cartDispatch = useAppDispatch();
    const alertDispatch = useAppDispatch();

    const [ render, setRender ] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
        cartDispatch(getItemsCart());
        cartDispatch(getTotalCart());
        cartDispatch(getItemTotalCart());
    }, [render]);

    const { cart } = useSelector((state:any) => state);

    const updateItemDispatch = (item: any, item_count: any) => {
        cartDispatch(updateItemCart(item, item_count));
    }

    const removeItemDispatch = (item:any) => {
        cartDispatch(removeItemCart(item))
    }

    const setAlertDispatch = (type: any, message: any) => {
        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        alertDispatch(setAlert({alertType: type, message: message}));

        setTimeout(() => {
            alertDispatch(removeAlert({alertType: null, message: null}))
        }, time);
    }

    const showItems = () => {
        return (
            <div className="w-full lg:w-3/4">
                <div className="hidden md:flex bg-[#8b716f] justify-between text-sm py-2 text-white mb-3">
                    <div className="w-[12%] text-center">#</div>
                    <div className="w-[40%] xl:w-7/12 text-center">Descrição</div>
                    <div className="w-[14%] text-center">Preço</div>
                    <div className="w-1/6 text-center">Cantidad</div>
                    <div className="w-[14%] text-center">Total</div>
                </div>
                <div>
                    {
                        cart.items &&
                        cart.items !== null &&
                        cart.items !== undefined &&
                        cart.items.length !== 0 &&
                        cart.items.map((item: any, index: any) => {
                            let count = item.count;
                            
                            return (
                                <div key={index}>
                                    <CartItem
                                        item = { item }
                                        count = { count }
                                        update_item = { updateItemDispatch }
                                        remove_item = { removeItemDispatch }
                                        render = { render }
                                        setRender = { setRender }
                                        setAlert = { setAlertDispatch }
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }

    const checkoutButton = () => {
        if(cart.total_items < 1) {
            return (
                <button type="button" className="w-6 h-6 bg-red-300">
                    ver
                </button>
            )
        } else {
            return (
                <div className="w-full mt-4 flex justify-center items-center">
                    <Link to='/checkout' className="bg-[#8b716f] w-full text-center py-3 text-white rounded-md text-sm uppercase border border-[#8b716f] transition-all duration-150 hover:bg-white hover:text-[#8b716f]">
                        Confirmar Compra
                    </Link>
                </div>
            )
        }
    }

    const verifyCartItems = () => {
        if(cart.total_items > 0) {
            return (
                <>
                    <h1 className="text-base text-[#8b716f] font-light mb-3">Carrinho de compras</h1>
                    <div className="flex flex-col lg:flex-row w-full lg:gap-6 lg:mb-8">
                        { showItems() }
                        <div className="mb-4 w-full lg:w-1/4">
                            <div className="bg-[#8b716f] p-2 text-white text-center text-sm">
                                Informações de compra
                            </div>
                            <div className="border border-[#8b716f]">
                                <div className="bg-gray-300 flex items-center justify-between p-3 text-sm">
                                    <div>Subtotal</div>
                                    <div>R$ { cart.amount.toFixed(2) }</div>
                                </div>
                                <div className="bg-gray-100 flex items-center justify-between p-3 text-sm">
                                    <div>Frete</div>
                                    <div>R$ 0.00</div>
                                </div>
                                <div className="bg-gray-300 flex items-center justify-between p-3 text-sm">
                                    <div>Descuento</div>
                                    <div>R$ { cart.discount }</div>
                                </div>
                                <div className="bg-gray-100 flex items-center justify-between p-3 text-sm">
                                    <div className="font-bold">Total</div>
                                    <div>R$ { cart.sale_amount.toFixed(2) }</div>
                                </div>
                            </div>
                            { checkoutButton() }
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div className="container">
                    <div className="flex flex-col justify-center items-center mb-10">
                        <h1 className="text-2xl text-[#8b716f] text-center">O seu carrinho está vazio</h1>
                        <p className="text-sm py-4 text-gray-400 text-center">Você ainda não tem produtos selecionados para comprar</p>
                        <Link to='/' className="bg-[#8b716f] uppercase py-3 px-6 border border-[#8b716f] rounded-md text-sm text-white transition-all duration-150 hover:bg-white hover:text-[#8b716f]">Ver Produtos</Link>
                    </div>
                </div>
            )
        }
    }

    return (
        <Layout>
            <div className="container">
                <div className="my-4 flex items-center flex-wrap text-[#8b716f]">
                    <span className="text-sm"><Link to='/'>Home</Link></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm text-gray-400">Carrinho</span>
                </div>
                {
                    verifyCartItems()
                }
            </div>
        </Layout>
    )
}

export default Cart
import Layout from "../hocs/Layout";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import WishlistItem from "../components/cart/wishlistitem";
import { useAppDispatch } from "../hooks/redux/hooks";
import { removeItemWishlist, getWishlistProducts } from "../service/wishlistService";

const WishlistPage = () => {

    const wishlistDispatch = useAppDispatch();

    const { wishlist } = useSelector((state:any) => state);
    const { user } = useSelector((state:any) => state);

    const removeItemDispatch = (item:any) => {
        wishlistDispatch(removeItemWishlist(item, user.user.user.id, user.access));
        wishlistDispatch(getWishlistProducts(user.access, user.user.user.id));
    }

    const showItems = () => {
        return (
            <div className="w-full">
                <div className="hidden md:flex bg-[#8b716f] justify-between text-sm py-2 text-white mb-3">
                    <div className="w-[12%] text-center">#</div>
                    <div className="w-[40%] xl:w-7/12 text-center">Descrição</div>
                    <div className="w-[18%] md:w-[28%] text-center">Preço</div>
                </div>
                <div>
                    {
                        wishlist.items &&
                        wishlist.items !== null &&
                        wishlist.items !== undefined &&
                        wishlist.items.length !== 0 &&
                        wishlist.items.map((item: any, index: any) => {
                            let count = item.count;
                            
                            return (
                                <div key={index}>
                                    <WishlistItem 
                                        item= { item }
                                        remove_item = { removeItemDispatch }
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }

    const verifyWishlistItems = () => {
        if(wishlist.total_items > 0) {
            return (
                <>
                    <h1 className="text-base text-[#8b716f] font-light mb-3">Lista de Desejos</h1>
                    <div className="flex flex-col lg:flex-row w-full lg:gap-6 lg:mb-8">
                        { showItems() }
                    </div>
                </>
            )
        } else {
            return (
                <div className="container">
                    <div className="flex flex-col justify-center items-center mb-10">
                        <h1 className="text-2xl text-[#8b716f] text-center">A sua lista de desejos está vazia</h1>
                        <p className="text-sm py-4 text-gray-400 text-center">Você ainda não tem produtos selecionados</p>
                        <Link to='/' className="bg-[#8b716f] uppercase py-3 px-6 border border-[#8b716f] rounded-md text-sm text-white transition-all duration-150 hover:bg-white hover:text-[#8b716f]">Ver Produtos</Link>
                    </div>
                </div>
            )
        }
    }

    return (
        <Layout>
            <div className="container">
                <div className="my-4 flex items-center flex-wrap text-[#8b716f]">
                    <span className="text-sm"><Link to='/'>Home</Link></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm text-gray-400">Lista de Desejos</span>
                </div>
                {
                    verifyWishlistItems()
                }
            </div>
        </Layout>
    )
}

export default WishlistPage;
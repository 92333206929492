import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 

const initialState: any = {
    items: [],
    total_items: 0
}

export const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: initialState,
    reducers: {
        addWishlist: (state, action: PayloadAction<any | null>) => {
            state.items.push(action.payload);
            state.total_items = state.total_items + 1;
        },
        resetWishList: (state, action: PayloadAction<any | null>) => {
            state.items = [];
            state.total_items = 0;
        }
    }
});

export default wishlistSlice.reducer;

export const {
    addWishlist,
    resetWishList
} = wishlistSlice.actions;
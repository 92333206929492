import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    highlights: [],
    bycategory: null,
    product: [],
    related: [],
    filtered: [],
    products_active: []
}

export const productSlice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
        setFullActiveProducts: (state, action: PayloadAction<any | null>) => {
            state.products_active = action.payload;
        },
        getFullActiveProductsFail: (state, action: PayloadAction<any | null>) => {
            state.products_active = [];
        },
        setLatestProductRequest: (state, action: PayloadAction<any | null>) => {
            state.products = action.payload;
        },
        setLatestProductRequestFail: (state, action: PayloadAction<any | null>) => {
            state.products = [];
        },
        setHighlightsProductRequest: (state, action: PayloadAction<any | null>) => {
            state.highlights = action.payload;
        },
        setHighlightsProductRequestFail: (state, action: PayloadAction<any | null>) => {
            state.highlights = [];
        },
        setProductsByCategory: (state, action: PayloadAction<any | null>) => {
            state.bycategory = action.payload;
        },
        setProductsByCategoryFails: (state, action: PayloadAction<any | null>) => {
            state.bycategory = null;
        },
        setProductsBySubCategory: (state, action: PayloadAction<any | null>) => {
            state.bycategory = action.payload;
        },
        setProductsBySubCategoryFails: (state, action: PayloadAction<any | null>) => {
            state.bycategory = null;
        },
        setProductsBySubCategorySub: (state, action: PayloadAction<any | null>) => {
            state.bycategory = action.payload;
        },
        setProductsBySubCategorySubFails: (state, action: PayloadAction<any | null>) => {
            state.bycategory = null;
        },
        setProductDetailsRequest: (state, action: PayloadAction<any | null>) => {
            state.product = action.payload;
        },
        setProductDetailsRequestFail: (state, action: PayloadAction<any | null>) => {
            state.product = [];
        },
        setRelatedProductsRequest: (state, action: PayloadAction<any | null>) => {
            state.related = action.payload;
        },
        setRelatedProductsRequestFail: (state, action: PayloadAction<any | null>) => {
            state.related = [];
        },
        setSearchProductsRequest: (state, action: PayloadAction<any | null>) => {
            state.filtered = action.payload;
        },
        setSearchProductsRequestFail: (state, action: PayloadAction<any | null>) => {
            state.filtered = [];
        },
    }
});

export default productSlice.reducer;

export const { 
    setFullActiveProducts,
    getFullActiveProductsFail,
    setLatestProductRequest, 
    setLatestProductRequestFail, 
    setHighlightsProductRequest, 
    setHighlightsProductRequestFail, 
    setProductsByCategory, 
    setProductsByCategoryFails, 
    setProductsBySubCategory,
    setProductsBySubCategoryFails,
    setProductsBySubCategorySub,
    setProductsBySubCategorySubFails,
    setProductDetailsRequest,
    setProductDetailsRequestFail,
    setRelatedProductsRequest,
    setRelatedProductsRequestFail,
    setSearchProductsRequest,
    setSearchProductsRequestFail
} = productSlice.actions;
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../hooks/redux/hooks';
import { useSelector } from 'react-redux';
import { login } from '../../service/userService';
import Layout from '../../hocs/Layout';
import logoAlpha from '../../assets/global/logoalpha.png';
import esmalte from '../../assets/global/esmalte.png';
import { cpfMask } from '../../helpers/cpfmask';
import { Oval } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';

const SigninPage = () => {

    const inputEmail = useRef(document.createElement("input"));

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
        inputEmail.current.focus();
    },[]);

    const { user } = useSelector((state:any) => state);

    const [ formData, setFormData ] = useState({
        passwordInput: '',
        emailInput: ''
    });

    const userDispatch = useAppDispatch();

    const {
        passwordInput,
        emailInput
    } = formData;

    const onChange = (e:any) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e:any) => {
        e.preventDefault();

        userDispatch(login({
            'email' : emailInput,
            'password': passwordInput
        }));

        navigate('/');
    }

    return (
        <Layout>
            <div className='min-w-screen min-h-screen flex items-center justify-center px-5 py-5'>
                <div className='bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden max-w-screen-lg'>
                    <div className='md:flex w-full'>
                        <div className='hidden md:flex w-1/2 bg-gray-200 py-10 px-10 flex-col items-center justify-between'>
                            <div>
                                <img src={ logoAlpha } className='w-60' />
                            </div>
                            <div>
                                <img src={ esmalte } className='w-60' />
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 py-10 px-5 md:px-10'>
                            <div className='mb-10 text-center'>
                                <h1 className='font-bold text-3xl text-gray-900'>Bem-vindo</h1>
                                <p>Insira suas informações para ingresar</p>
                            </div>
                            <div>
                                <form onSubmit={ e => onSubmit(e) }>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='emailInput' className='text-xs font-semibold px-1'>Email:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                    </svg>
                                                </div>
                                                <input ref={inputEmail} value={ emailInput } onChange={ e => onChange(e) } type="email" name='emailInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite seu Email" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='cpfInput' className='text-xs font-semibold px-1'>Senha de acesso:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                    </svg>
                                                </div>
                                                <input value={ passwordInput } onChange={ e => onChange(e) } type="password" name='passwordInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite sua senha" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-5">
                                        {user.loading ?
                                            <button className="w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300 flex justify-center items-center">
                                                <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                                            </button>
                                            :
                                            <button type='submit' className=" block w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300">Entrar</button>
                                        }
                                        </div>
                                    </div>
                                    <div className='flex -mx-3 justify-end'>
                                        <p className='text-sm'>Você ainda não tem cadastro?, <Link className='underline' to='/signup'>Cadastre-se</Link></p>
                                    </div>
                                    <div className='flex -mx-3 justify-start mt-8'>
                                        <Link to='/reset_password' className='text-sm'>Esqueceu sua senha?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SigninPage
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import Slider0 from '../../assets/slider/slider0.jpg';
import Slider1 from '../../assets/slider/slider1.jpg';
import Slider2 from '../../assets/slider/slider2.jpg';
import Slider3 from '../../assets/slider/slider3.jpg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SliderMain = () => {
    return (
        <div className="slider">
            <div className="container">
                <div className="wrapper">
                <Swiper 
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={true}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    >
                    <SwiperSlide>
                        <div className="item">
                            <div className="image object-cover">
                                <img src={ Slider0 } alt="" className='w-full' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="image object-cover">
                                <img src={ Slider1 } alt="" className='w-full'/>
                            </div>
                            {
                                /*<div className="text-content flexcol">
                                    <h4>Shoes Fashion</h4>
                                    <h2><span>Come and Get it</span><br /><span>Brand New Shoes</span></h2>
                                    <a href="#" className="primary-button">Shop Now</a>
                                </div>*/
                            }
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="image object-cover">
                                <img src={ Slider2 } alt="" className='w-full' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="image object-cover">
                                <img src={ Slider3 } alt="" className='w-full' />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                </div>
            </div>
        </div>
    )
}

export default SliderMain;
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../hooks/redux/hooks';
import { register } from '../../service/userService';
import { Oval } from 'react-loader-spinner'
import { useSelector } from 'react-redux';
import { setAlert, removeAlert } from '../../redux/slice/commons/alertSlice';

import Layout from '../../hocs/Layout';
import logoAlpha from '../../assets/global/logoalpha.png';
import esmalte from '../../assets/global/esmalte.png';
import { cpfMask } from '../../helpers/cpfmask';

const SignupPage = () => {

    const inputName = useRef(document.createElement("input"));

    useEffect(() => {
        window.scrollTo(0,0);
        inputName.current.focus();
    },[]);

    const { user } = useSelector((state:any) => state);

    const [ accountCreated, setAccountCreated ] = useState(false);

    const [ formData, setFormData ] = useState({
        fullNameInput: '',
        cpfInput: '',
        emailInput: '',
        passwordInput: '',
        confirmPasswordInput: ''
    });

    const userDispatch = useAppDispatch();
    const alertDispatch = useAppDispatch();

    const {
        fullNameInput,
        cpfInput,
        emailInput,
        passwordInput,
        confirmPasswordInput
    } = formData;

    const onChange = (e:any) => setFormData({
        ...formData,
        [e.target.name]: e.target.name === 'cpfInput' ? cpfMask(e.target.value) : e.target.value
    });

    const onSubmit = (e:any) => {
        e.preventDefault();

        if(passwordInput != confirmPasswordInput) {
            
            const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

            window.scrollTo(0,0)

            alertDispatch(setAlert({alertType: 'red', message: 'As senhas não correspondem, verifique.'}));

            setTimeout(() => {
                alertDispatch(removeAlert({alertType: null, message: null}))
            }, time);
            
        } else {
            userDispatch(register({
                'name': fullNameInput,
                'cpf': cpfInput,
                'email' : emailInput,
                'password': passwordInput,
                'password_confirmation': confirmPasswordInput
            }));

            setAccountCreated(true);
        }
    }

    return (
        <Layout>
            <div className='min-w-screen min-h-screen flex items-center justify-center px-5 py-5'>
                <div className='bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden max-w-screen-lg'>
                    <div className='md:flex w-full'>
                        <div className='hidden md:flex w-1/2 bg-gray-200 py-10 px-10 flex-col items-center justify-between'>
                            <div>
                                <img src={ logoAlpha } className='w-60' />
                            </div>
                            <div>
                                <img src={ esmalte } className='w-60' />
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 py-10 px-5 md:px-10'>
                            <div className='mb-10 text-center'>
                                <h1 className='font-bold text-3xl text-gray-900'>Registre-se</h1>
                                <p>Insira suas informações para se cadastrar</p>
                            </div>
                            <div>
                                <form onSubmit={ e => onSubmit(e) }>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='fullNameInput' className='text-xs font-semibold px-1'>Nome Completo:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                    </svg>
                                                </div>
                                                <input ref={inputName} value={ fullNameInput } onChange={ e => onChange(e) } type="text" name='fullNameInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite seu nome completo" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='cpfInput' className='text-xs font-semibold px-1'>Cpf:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                    </svg>
                                                </div>
                                                <input value={ cpfInput } onChange={ e => onChange(e) } type="text" name='cpfInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite seu Cpf" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='emailInput' className='text-xs font-semibold px-1'>Email:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                    </svg>
                                                </div>
                                                <input value={ emailInput } onChange={ e => onChange(e) } type="email" name='emailInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite seu Email" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='passwordInput' className='text-xs font-semibold px-1'>Senha de acesso:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                    </svg>
                                                </div>
                                                <input value={ passwordInput } onChange={ e => onChange(e) } type="password" minLength={8} name='passwordInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Digite sua senha de acesso" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex -mx-3'>
                                        <div className='w-full px-3 mb-5'>
                                            <label htmlFor='confirmPasswordInput' className='text-xs font-semibold px-1'>Digite novamente sua Senha de Acesso:</label>
                                            <div className='flex'>
                                                <div className='w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-gray-400">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                                    </svg>
                                                </div>
                                                <input value={ confirmPasswordInput } onChange={ e => onChange(e) } type="password" minLength={8} name='confirmPasswordInput' className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#8b716f] placeholder:text-xs" placeholder="Re Digite sua senha de acesso" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-5">
                                        {user.loading ?
                                            <button className="w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300 flex justify-center items-center">
                                                <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                                            </button>
                                            :
                                            <button type='submit' className=" block w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300">REGISTRE-SE</button>
                                        }
                                        </div>
                                    </div>
                                    <div className='flex -mx-3 justify-end'>
                                        <p className='text-sm'>Você ja cadastro?, <Link className='underline' to='/signin'>Ingrese</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SignupPage
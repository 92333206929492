import axiosInstance from "./api";
import { AxiosResponse, AxiosError } from "axios";

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const registerOrder = async (data: any) => {
    await csrf();

    try {

        const response: AxiosResponse = await axiosInstance.post('/api/orders/register', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 201) {
            return response.data;
        } else {
            return false;
        }

    } catch(error: any) {
        return false;
    }
}

export const registerOrderItem = async (data: any) => {
    await csrf();

    try {

        const response: AxiosResponse = await axiosInstance.post('/api/orders/registerorderitem', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 201) {
            return response.data;
        } else {
            return false;
        }

    } catch(error: any) {
        return false;
    }
}
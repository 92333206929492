import Layout from "../hocs/Layout";
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux/hooks';
import { Fragment, useState, useEffect } from 'react';
import { getProductsBySubCategorySub } from "../service/productService";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import ProductCard from "../components/common/productcard";
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid';
import ReactPaginate from 'react-paginate'; 

const sortOptions: any = [
    { name: 'Mais popular', href: '#', current: true },
    { name: 'Melhor classificação', href: '#', current: false },
    { name: 'O mais novo', href: '#', current: false },
    { name: 'Preço: baixo a alto', href: '#', current: false },
    { name: 'Preço: alto a baixo', href: '#', current: false }
];

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ');
}

const Subcategorysub = () => {

    const params = useParams();
    const sub_category_sub_slug = params.slug;

    const productBySubCategorySubDispatch = useAppDispatch();

    useEffect(() => {
        productBySubCategorySubDispatch(getProductsBySubCategorySub({'sub_category_sub':sub_category_sub_slug }))
    }, [sub_category_sub_slug]);

    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

    const { products } = useSelector((state:any) => state);

    const CapitalizeFirstLetter = (Phrase: string) => {
        
        return Phrase.charAt(0).toUpperCase() + Phrase.slice(1);

    }

    const handleChangePage = (pageNumber: any) => {
        let pageSelected = pageNumber.selected + 1;

        productBySubCategorySubDispatch(getProductsBySubCategorySub({'sub_category_sub':sub_category_sub_slug }, pageSelected))
    }

    return (
        <Layout>
            <Transition.Root show={ mobileFiltersOpen } as={ Fragment }>
                <Dialog as="div" className="relative z-40 lg:hidden"  onClose={ setMobileFiltersOpen }>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                <div className="flex items-center justify-between px-4">
                                    <h2 className="text-lg font-medium text-gray-900">Subcategorias</h2>
                                    <button type="button" className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400" onClick={() => setMobileFiltersOpen(false)}>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <form className="mt-4 border-t border-gray-200">
                                    <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                                        
                                    </ul>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="container">
                <div className="my-6 flex items-center flex-wrap text-[#8b716f]">
                    <span className="text-sm"><Link to='/'>Home</Link></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    {
                        products.bycategory !== null && 
                        products.bycategory.sub_category_sub &&
                        products.bycategory.sub_category_sub !== null &&
                        products.bycategory.sub_category_sub !== undefined && 
                        (
                            <span className="text-sm"><Link to={`/category/${products.bycategory.sub_category_sub.subcategory.category.slug}`}>{CapitalizeFirstLetter(products.bycategory.sub_category_sub.subcategory.category.name.toLowerCase())}</Link></span>   
                        )
                    }
                    
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>

                    {
                        products.bycategory !== null && 
                        products.bycategory.sub_category_sub &&
                        products.bycategory.sub_category_sub !== null &&
                        products.bycategory.sub_category_sub !== undefined && 
                        (
                            <span className="text-sm"><Link to={`/subcategory/${products.bycategory.sub_category_sub.subcategory.slug}`}>{products.bycategory.sub_category_sub.subcategory.name}</Link></span>   
                        )
                    }

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm text-gray-400">{ sub_category_sub_slug }</span>
                </div>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex items-baseline justify-between border-b border-[#8b716f] pb-2 pt-6">
                        <h1 className="text-xl font-bold tracking-tight text-[#8b716f]">Produtos</h1>
                        <div className="flex items-center">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        Ordem
                                        <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {
                                                sortOptions.map((option:any) => (
                                                    <Menu.Item key={option.name}>
                                                        {
                                                            ({ active }) => (
                                                                <a href={ option.href } className={classNames(option.current ? 'font-medium text-gray-900' : 'text-gray-500', active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm')}>
                                                                    { option.name }
                                                                </a>
                                                            )
                                                        }
                                                    </Menu.Item>
                                                ))
                                            }
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            <button type="button" className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden" onClick={() => setMobileFiltersOpen(true)}>
                                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <section aria-labelledby="products-heading" className="pb-24 pt-6" >
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            <form className="hidden lg:block">
                                <ul role="list" className="space-y-4 pb-6 text-sm font-medium text-[#8b716f]"></ul>
                            </form>
                            <div className="lg:col-span-3 flex flex-col sm:flex-row sm:flex-wrap">
                                { 
                                    products.bycategory !== null && 
                                    products.bycategory.products.data &&
                                    products.bycategory.products.data !== null &&
                                    products.bycategory.products.data !== undefined && 
                                    products.bycategory.products.data.map((product:any) => {
                                        if(product.status == 1) {
                                            return (
                                                <div key={product.id} className='mt-4 sm:w-1/2 md:w-1/3 lg:w-1/4'>
                                                    <ProductCard id={product.id} name={product.name} slug={product.slug} image={process.env.REACT_APP_API_URL + '/' +product.image} price={product.regular_price} sale_price={ product.sale_price } product={product} />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className="flex p-4 justify-center items-center mt-4">
                            <div className="flex justify-between w-full sm:hidden">
                                <a href="#" className="text-[#8b716f]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                </a>
                                <a href="#" className="text-[#8b716f]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </a>
                            </div>
                            <div className="hidden sm:flex flex-col">
                                {
                                    products.bycategory &&
                                    products.bycategory.products &&
                                    products.bycategory.products !== null && (
                                        //renderPagination(products.bycategory.products.links, products.bycategory.products.last_page)
                                        <ReactPaginate 
                                            pageCount={ products.bycategory.products.last_page }
                                            previousLabel={ 
                                                <div className="relative inline-flex items-center rounded-l-md px-2 py-2 text-[#8b716f] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                                    <span className="sr-only">Previous</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                                    </svg>
                                                </div>
                                             }
                                            nextLabel={ 
                                                <div className="relative inline-flex items-center rounded-r-md px-2 py-2 text-[#8b716f] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                                    <span className="sr-only">Next</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                    </svg>
                                                </div>
                                            }
                                            breakLabel={ <span className="relative inline-flex items-center px-4 py-2 h-full text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span> }
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={ handleChangePage }
                                            containerClassName= "isolate inline-flex -space-x-px rounded-md shadow-sm"
                                            pageClassName= "block relative  inline-flex items-center px-4 py-2 text-sm font-semibold text-[#8b716f] ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                            activeClassName="z-10 bg-[#8b716f] text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8b716f] hover:!bg-[#8b716f]"
                                        />
                                    )
                                    
                                }
                                {
                                    /*<div className="mt-3 text-center">
                                        <p className="text-sm text-gray-700">
                                            Mostrando <span className="font-medium text-[#8b716f]">1</span> a <span className="font-medium text-[#8b716f]">10</span> de { ' ' }
                                            <span className="font-medium text-[#8b716f]">97</span> resultados.
                                        </p>
                                    </div>*/
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

export default Subcategorysub;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';

import { RouterProvider } from 'react-router-dom';
import router from './router'; 

import { SkeletonTheme } from 'react-loading-skeleton';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <SkeletonTheme baseColor="#eeeeeea7" highlightColor="#9c9c9c">
      <Provider store={ store }>
        <PersistGate persistor={ persistor }>
          <RouterProvider router={ router } />
        </PersistGate>
      </Provider>
    </SkeletonTheme>
);
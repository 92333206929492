import { Link, useNavigate } from 'react-router-dom';
import { useState, Fragment, useRef } from "react";
import { Oval } from 'react-loader-spinner'
import { useAppDispatch } from '../../hooks/redux/hooks';
import { addItemCart, getItemsCart, getTotalCart, getItemTotalCart } from "../../service/cartService";
import { StarIcon } from "@heroicons/react/24/solid";
import ProductHeart from './productheart';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addWishlistitem, removeItemWishlist, getWishlistProducts } from '../../service/wishlistService';

import { Dialog, Transition } from '@headlessui/react';

interface ProductData {
    id: number,
    name: string,
    slug: string,
    image: string,
    price: number,
    sale_price: number,
    product: any
}

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
}

const ProductCard = (props: ProductData) => {

    const [ loading, setLoading ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);
    
    const cancelButtonRef = useRef(null);

    const cartDispatch = useAppDispatch();
    const wishlistDispatch = useAppDispatch();

    const navigate = useNavigate();

    const { user } = useSelector((state:any) => state);
    const { wishlist } = useSelector((state:any) => state);

    const addToCart = async () => {
        if(props.product !== null && props.product !== undefined && props.product && props.product.quantity > 0) {
            setLoading(true);
            await cartDispatch(addItemCart(props.product));
            await cartDispatch(getItemsCart());
            await cartDispatch(getTotalCart());
            await cartDispatch(getItemTotalCart());
            setLoading(false);
            navigate('/cart');
        }
    }

    const PromocionalPrice = () =>{

        if(Number(props.product.sale_price) < Number(props.product.regular_price)) {

            let Percent = (props.product.sale_price * 100) / props.product.regular_price;
            let NegativePercent = (Percent - 100);

            return(
                <div className="bg-red-600 text-white text-xs py-1 px-2 rounded-md uppercase">{ NegativePercent.toFixed(0) } %</div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const ShowPrice = () => {

        if(Number(props.product.sale_price > 0)) {
            return(
                <p className="text-xl font-bold text-gray-900"><span>R$ { props.product.sale_price }</span></p>
            )
        } else {
            return (
                <p className="text-xl font-bold text-gray-900">R$ { props.product.regular_price }</p>
            )
        }
    }

    const ShowPriceFront = () => {
        if(Number(props.sale_price > 0)) {
            return(
                <span className='text-sm'>R$ { props.price }<span className='ml-1 line-through text-xs'>{ props.sale_price }</span></span>
            )
        } else {
            return (
                <span className='text-sm'>R$ { props.price }</span>
            )
        }
    }

    const ShowStock = () => {
        
        if(Number(props.product.quantity) > 0) {
            return(
                <p className="text-sm text-gray-900"><span className="text-green-600">Disponível. ({ props.product.quantity } Unidades)</span></p>
            )
        } else {
            return (
                <p className="text-sm text-gray-900"><span className="text-red-600">Não disponível.</span></p>
            )
        }
    }

    const modalInformation = () => {
        return (
            <Transition.Root show={ openModal } as={ Fragment }>
                <Dialog as="div" className="relative z-20" initialFocus={ cancelButtonRef } onClose={ setOpenModal }>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className='fixed inset-0 z-10 overflow-y-auto'>
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className='pb-2 border-b-2 border-[#8b716f]'>
                                            <h1 className='text-base text-[#8b716f]'>{ props.name }</h1>
                                        </div>
                                        <div className='py-2 flex'>
                                            <div className='w-2/5 flex items-center justify-center'>
                                                <img src={ props.product.image } />
                                            </div>
                                            <div className='w-3/5 pl-2 flex flex-col'>
                                                <div className="flex items-center mb-3 justify-between">
                                                    {
                                                        props.product.featured > 0 && (
                                                            <div className="bg-[#8b716f] text-white text-xs py-1 px-2 rounded-md uppercase mr-3">Novo</div>
                                                        )
                                                    }
                                                    {
                                                        props.product.sale_price > 0 && (
                                                            PromocionalPrice()
                                                        )
                                                    }
                                                    <div className='flex items-center justify-end flex-1'>
                                                        <p className="text-gray-900 text-sm">Codigo: { props.product.sku }</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="flex items-center">
                                                        <div className="flex items-center">
                                                            {
                                                                [0,1,2,3,4].map((rating) => (
                                                                    <StarIcon key={rating} className={ classNames(2 > rating ? 'text-[#efb810]': 'text-gray-300', 'h-4 w-4 flex-shrink-0')} aria-hidden='true' />
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    {
                                                        ShowPrice()
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    {
                                                        ShowStock()
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    <div className="text-gray-600 space-y-6 text-sm" dangerouslySetInnerHTML={{ __html:props.product.description.substring(0, 150)+'...' }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-full border-t-2 border-[#8b716f] flex justify-center items-center pt-3'>
                                            <button type='button' onClick={addToCart} className='bg-[#8b716f] border text-white text-xs uppercase py-2 px-4 rounded-md hover:border-[#8b716f] hover:bg-white hover:text-[#8b716f] transition-all ease-in-out duration-200 outline-none'>
                                                Adicionar ao carrinho
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

    const showModal = (e: any) => {
        e.preventDefault();
        setOpenModal(true);
    }

    const addToWhishList = async () => {
        if(user.isAuthenticated) {
            
            let isPresent = false;

            if(wishlist && wishlist !== null && wishlist !== undefined) {
                wishlist.items.map((item: any) => {
                    if (item.id.toString() === props.product.id.toString()) {
                        isPresent = true;
                    }
                });
            }

            if(isPresent) {
                await wishlistDispatch(removeItemWishlist(props.product.id, user.user.user.id, user.access));
                await wishlistDispatch(getWishlistProducts(user.access, user.user.user.id));
            } else {

                await wishlistDispatch(addWishlistitem(props.product, user.access, user.user.user.id));
            }

        } else {
            toast.error("Es necesario estar registrado para salvar sua lista de desejos.", {
                theme: "colored",
                onClose: () => navigate('/signin')
            });
        }
    }

    return (
        <div className='flex justify-center items-center flex-col relative'>
            <Link to={`/product/${props.slug}`} className='flex justify-center items-center flex-col group'>
                <div className='border rounded-md w-11/12'>
                    <img src={ props.image } className='group-hover:blur-sm transition-all ease-in-out duration-200' />
                </div>
                <div className='w-full mt-4 p-3'>
                    <p className='text-sm h-10 text-[#8b716f]'>{ props.name }</p>
                    <hr className='border-[#8b716f] my-1' />
                    <div className='flex justify-between'>
                        <span className='text-sm text-gray-400'>{ props.id }</span>
                        {
                            ShowPriceFront()
                        }
                    </div>
                </div>
                <div className='absolute top-4 right-5 w-7 h-7 translate-x-10 group-hover:-translate-x-1 transition-all ease-in-out duration-200 opacity-0 group-hover:opacity-100'>
                    <ProductHeart addToWishList={ addToWhishList } productID={ props.id } />
                    <button type='button' className='w-7 h-7 z-10 flex justify-center items-center outline-none' onClick={ showModal }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-gray-600 hover:text-[#8b716f]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </button>
                </div>
                <div className='absolute top-1/2 opacity-0 transition-all ease-in-out duration-200 group-hover:opacity-100'>
                    {
                        loading ?
                        <button type="button" className='bg-[#8b716f] border text-white text-xs uppercase py-2 px-4 rounded-md hover:border-[#8b716f] hover:bg-white hover:text-[#8b716f] transition-all ease-in-out duration-200'>
                            <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                        </button>
                        :
                        <button type='button' onClick={addToCart} className='bg-[#8b716f] border text-white text-xs uppercase py-2 px-4 rounded-md hover:border-[#8b716f] hover:bg-white hover:text-[#8b716f] transition-all ease-in-out duration-200'>
                            Adicionar ao carrinho
                        </button>
                    }
                    
                </div>
            </Link>
            { modalInformation() }
        </div>
    )
}

export default ProductCard;
import BannerFrete from '../../assets/global/frete.png';

const ShipInfo = () => {
    return (
        <div className='sm: mt-4 md:mt-8'>
            <img src={ BannerFrete } className='w-full'/>
        </div>
    )
}

export default ShipInfo;
import axiosInstance from "./api";
import { AxiosResponse, AxiosError } from "axios";
import { Thunk } from "../redux/store";
import { setAdd_Item, setAdd_Item_Fail, setGet_Items, setGet_Total, setGet_Item_Total, setUpdate_Item, setRemove_item, setDiscount, setSaleAmount } from "../redux/slice/products/cartSlice";
import moment from 'moment';

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const addItemCart = (productData: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {
        
    if(localStorage.getItem('access')) {

    } else {
        let cart = [];
        let shouldAddItem = true;

        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart') || '{}');
        }

        cart.map((item: any) => {
            if(productData.id.toString() === item.product.id.toString()) {
                shouldAddItem = false;
            }
        });

        const order_item = {
            product: productData,
            count: 1
        };

        if(shouldAddItem) {
            cart.push(order_item);
        }

        dispatch(setAdd_Item(cart));
    }
}

export const getItemsCart = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {

    if(localStorage.getItem('access')) {

    } else {
        dispatch(setGet_Items(null));
    }
}

export const getTotalCart = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {

    if(localStorage.getItem('access')) {

    } else {
        let total = 0.0;
        let sale_amount = 0.0;
        let cart = [];

        if (localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart') || '{}');

            cart.map((item: any) => {
                total += parseFloat(item.product.sale_price) * parseFloat(item.count);
                sale_amount += parseFloat(item.product.sale_price) * parseFloat(item.count);
            });
        }

        dispatch(setGet_Total([parseFloat(total.toFixed(2)), parseFloat(sale_amount.toFixed(2))]));
    }

}

export const getItemTotalCart = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {

    if(localStorage.getItem('access')) {

    } else {
        let total = 0;

        if (localStorage.getItem('cart')) {
            total = JSON.parse(localStorage.getItem('cart') || '{}').length;
        }

        dispatch(setGet_Item_Total(total));
    }

}

export const removeItemCart = (item:any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {
    
    if(localStorage.getItem('access')) {

    } else {
        let cart = [];
        let new_cart:any = [];

        if (localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart') || '{}');

            cart.map((cart_item:any) => {
                if (cart_item.product.id.toString() !== item.product.id.toString()) {
                    new_cart.push(cart_item);
                }
            });
        }

        dispatch(setRemove_item(new_cart));
    }

}

export const updateItemCart = (item: any, count: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {

    if(localStorage.getItem('access')) {

    } else {
        let cart:any = [];

        if (localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart') || '{}');

            cart.map((cart_item:any, index: any) => {
                if (cart_item.product.id.toString() === item.product.id.toString()) {
                    cart[index].count = parseInt(count);
                }
            });
        }

        dispatch(setUpdate_Item(cart));
    }

}

export const checkCoupons = async (cuponCode: any, cartValue: any) => {
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.get('/api/coupons/check/' + cuponCode + '/' + moment().format('YY-MM-DD')  + '/' + cartValue, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.coupon;

    } catch(error: any) {
        return false;
    }

}

export const updateDiscount = (discountValue: number): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {

    dispatch(setDiscount(discountValue));

}

export const updateSaleAmount = (saleAmount: number): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError | any> => {
    dispatch(setSaleAmount(saleAmount));
}

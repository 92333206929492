import Layout from "../hocs/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../hooks/redux/hooks";
import { useState, useEffect } from "react";
import CheckoutItem from "../components/cart/checkoutitem";
import { cpfMask } from "../helpers/cpfmask";
import { cepMask } from "../helpers/cepmask";
import { validateEmail } from "../helpers/validateEmail";
import { toast } from 'react-toastify';
import { verifyUser, register, login } from "../service/userService";
import { TailSpin } from 'react-loader-spinner'
import { setLoading, removeLoading } from "../redux/slice/users/userSlice";
import { Oval } from 'react-loader-spinner'
import { checkCoupons, updateDiscount, updateSaleAmount } from "../service/cartService";

import { registerOrder, registerOrderItem } from "../service/checkoutService";

const Checkout = () => {

    const { cart } = useSelector((state:any) => state);
    const { user } = useSelector((state:any) => state);

    const customId = "user-register-error";
    const userDispatch = useAppDispatch();
    const couponDispatch = useAppDispatch();
    const navigate = useNavigate();

    const [ cupom, setCupom ] = useState('');

    const [ emailInput, setEmailInput ] = useState('');
    const [ cpfInput, setCpfInput ] = useState('');
    const [ fullNameInput, setFullNameInput ] = useState('');

    const [ cepInput, setCepInput ] = useState('');
    const [ ruaInput, setRuaInput ] = useState('');
    const [ numeroInput, setNumeroInput ] = useState('');
    const [ complementoInput, setComplementoInput ] = useState('');
    const [ bairroInput, setBairroInput ] = useState('');
    const [ cidadeInput, setCidadeInput ] = useState('');
    const [ ufInput, setUfInput ] = useState('');
    const [ ufFullData, setUfFullData ] = useState('');
    const [ telefoneInput, setTelefoneInput ] = useState('');
    const [ disabled, setDisabled ] = useState(false);

    useEffect(() => {
        if(user.user && user.user !== null) {
            if(user.user.user.shipping.length !== 0) {
                setCepInput(user.user.user.shipping[0].cep_number);
                setRuaInput(user.user.user.shipping[0].address);
                setNumeroInput(user.user.user.shipping[0].address_number);
                setComplementoInput(user.user.user.shipping[0].address_extra_info);
                setBairroInput(user.user.user.shipping[0].address_bairro);
                setCidadeInput(user.user.user.shipping[0].city);
                setUfFullData(user.user.user.shipping[0].state);
                setTelefoneInput(user.user.user.shipping[0].phone_number);

                setDisabled(true);
            }
        }
    }, [user.user]);

    const showItems = () => {
        return (
            <div className="w-full lg:w-3/4">
                <div className="hidden md:flex bg-[#8b716f] justify-between text-sm py-2 text-white mb-3">
                    <div className="w-[60%] xl:w-7/12 text-center">Descrição</div>
                    <div className="w-[14%] text-center">Preço</div>
                    <div className="w-1/6 text-center">Cantidad</div>
                    <div className="w-[14%] text-center">Total</div>
                </div>
                <div>
                    {
                        cart.items &&
                        cart.items !== null &&
                        cart.items !== undefined &&
                        cart.items.length !== 0 &&
                        cart.items.map((item: any, index: any) => {
                            let count = item.count;
                            
                            return (
                                <div key={index}>
                                    <CheckoutItem 
                                        item = { item }
                                        count = { count }
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }

    const showProductsItems = () => {
        if(cart.total_items > 0) {
            return (
                <>
                    <h1 className="text-base text-[#8b716f] font-light mb-3">Carrinho de compras</h1>
                    <div className="flex flex-col lg:flex-row w-full lg:gap-6 lg:mb-8">
                        { showItems() }
                        <div className="mb-4 w-full lg:w-1/4">
                            <div className="bg-[#8b716f] p-2 text-white text-center text-sm">
                                Informações de compra
                            </div>
                            <div className="border border-[#8b716f]">
                                <div className="bg-gray-300 flex items-center justify-between p-3 text-sm">
                                    <div>Subtotal</div>
                                    <div>R$ { cart.amount.toFixed(2) }</div>
                                </div>
                                <div className="bg-gray-100 flex items-center justify-between p-3 text-sm">
                                    <div>Frete</div>
                                    <div>R$ 0.00</div>
                                </div>
                                <div className="bg-gray-300 flex items-center justify-between p-3 text-sm">
                                    <div>Descuento</div>
                                    <div>R$ { cart.discount }</div>
                                </div>
                                <div className="bg-gray-100 flex items-center justify-between p-3 text-sm">
                                    <div className="font-bold">Total</div>
                                    <div>R$ { cart.sale_amount.toFixed(2) }</div>
                                </div>
                            </div>
                            <div className="my-6">
                                <p className="text-[#8b716f] font-bold text-sm">Cupom de desconto</p>
                                <div className="flex items-center gap-3">
                                    <input type="text" onChange={ (e) => setCupom(e.target.value) } className="border border-[#8b716f] uppercase rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]" placeholder="Digite o Codigo" />
                                    <button onClick={ couponDescount } className="bg-[#8b716f] w-full text-center py-2 text-white rounded-md text-sm uppercase border border-[#8b716f] transition-all duration-150 hover:bg-white hover:text-[#8b716f]">Aplicar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } 
    }

    const couponDescount = () => {
        if(cupom.length === 0) {
            toast.error("Por favor digite um cupom válido.", {
                theme: "colored",
            });
            return;
        }

        if(cart.discount.toFixed(2) == 0) {
            checkCoupons(cupom, cart.sale_amount.toFixed(2)).then((response) => {

                if(response) {
                    let discountValue = 0;
                    let amoutAfterDiscount = 0;

                    if(response.type == 'fixed') {
                        discountValue = response.value;
                    } else {
                        discountValue = (cart.sale_amount.toFixed(2) * response.value) / 100;
                    }

                    amoutAfterDiscount = cart.sale_amount.toFixed(2) - discountValue;
                    couponDispatch(updateDiscount(discountValue));
                    couponDispatch(updateSaleAmount(amoutAfterDiscount));

                } else {
                    toast.error("Ocurreu um erro por favor tente mais tarde.", {
                        theme: "colored",
                    });
                    return;
                }
            })
        }
    }

    const verifyCpf = (e: any) => {
        setCpfInput(cpfMask(e.target.value));
    }

    const verifyCep = (e: any) => {

        setCepInput(cepMask(e.target.value));
        
        if(e.target.value.length >= 8) {

            let cepOriginal = e.target.value;
            let cepCode = '';
            
            cepCode = cepOriginal.replace('.','').replace('-','');

            if(cepCode.length == 8) {
                userDispatch(setLoading(null));
                let url = 'http://viacep.com.br/ws/' + cepCode  + '/json/';

                fetch(url).then(response => response.json())
                    .then(data => {     
                        setRuaInput(data.logradouro);
                        setBairroInput(data.bairro);
                        setCidadeInput(data.localidade);
                        setUfInput(data.uf);

                        let urlUF = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + data.uf + '/';

                        fetch(urlUF).then(response => response.json())
                            .then(dataUf => {
                                setUfFullData(dataUf.nome);
                            })
                        
                        userDispatch(removeLoading(null));
                    });
            }
        }
    }

    const userCheck = () => {
        if(emailInput != '' && cpfInput != '' && fullNameInput != '') {
            
            if(!validateEmail(emailInput)) {
                toast.error("Por favor digite um email válido.", {
                    theme: "colored",
                    toastId: customId
                });
                return;
            }

            if(cpfInput.length < 14) {
                toast.error("CPF inválido.", {
                    theme: "colored",
                    toastId: customId
                });
                return;
            }

            if(fullNameInput.length < 10) {
                toast.error("Digite um nome completo por favor.", {
                    theme: "colored",
                    toastId: customId
                });
                return;
            }

            userDispatch(setLoading(null));

            verifyUser({
                'email' : emailInput,
                'cpf': cpfInput
            }).then(response => {
                userDispatch(removeLoading(null));

                if(response != null || response != undefined) {
                    if(response.success) {
                        toast.error("Usuário registrado, faça o login com sua conta", {
                            theme: "colored",
                            toastId: customId,
                            onClose: () => navigate('/signin')
                        });
                    } else {
                        
                        let passwordString = cpfInput.replace('.','');
                        passwordString = passwordString.replace('.','');
                        passwordString = passwordString.replace('-','');

                        userDispatch(register({
                            'name': fullNameInput,
                            'cpf': cpfInput,
                            'email' : emailInput,
                            'password': passwordString,
                            'password_confirmation': passwordString
                        }));

                        toast.success("Usuário registrado, Uma senha genérica foi criada. altere-o no login", {
                            theme: "colored",
                            toastId: customId,
                        });

                        userDispatch(login({
                            'email' : emailInput,
                            'password': passwordString
                        }));

                    }
                } 
            });
        }
    }

    const showUserDataForm = () => {
        if(user.isAuthenticated) {
            return (
                <>
                    <div className="text-[#8b716f]">
                        <h3 className="text-sm border-b-2 pb-1 mb-2 border-[#8b716f]">DADOS DO TITULAR</h3>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Endereço de email:</label>
                                <input name='emailInput' value={ user.user.user.email } type="email" placeholder="Email" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] bg-gray-200" disabled/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Cpf:</label>
                                <input name='cpfInput' value={ user.user.user.cpf } type="text" placeholder="Digite seu Cpf. Ex: ###.###.###-##" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] bg-gray-200" disabled/>
                            </div>
                            <div className="flex flex-col mb-4 col-span-2">
                                <label className="text-sm mb-1">Nome Completo:</label>
                                <input name='nameInput' value={ user.user.user.name } type="text" placeholder="Nome" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] bg-gray-200" disabled/>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return(
                <>
                    <div className="text-[#8b716f]">
                        <h3 className="text-sm border-b-2 pb-1 mb-2 border-[#8b716f]">DADOS DO TITULAR</h3>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Endereço de email:</label>
                                <input name='emailInput' value={ emailInput } onChange={ (e) => setEmailInput(e.target.value) } onBlur={ userCheck } type="email" placeholder="Email" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]"/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Cpf:</label>
                                <input name='cpfInput' value={ cpfInput } onChange={ e => verifyCpf(e) } onBlur={ userCheck } type="text" placeholder="Digite seu Cpf. Ex: ###.###.###-##" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]"/>
                            </div>
                            <div className="flex flex-col mb-4 col-span-2">
                                <label className="text-sm mb-1">Nome Completo:</label>
                                <input name='sobrenomeInput' value={ fullNameInput } onChange={ e => setFullNameInput(e.target.value) } onBlur={ userCheck } type="text" placeholder="Sobrenome" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]"/>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const showDisabledMessage = () => {
        if(disabled) {
            return (
                <>
                    <p className="text-sm mb-4">Para modificar os dados, por favor, vá para o perfil do usuário.</p>
                </>
            )
        }
    }

    const showFormUserRegister = () => {
        if(user.isAuthenticated) {
            return (
                <div>
                    <div className="text-[#8b716f]">
                        <h3 className="text-sm border-b-2 pb-1 mb-2 border-[#8b716f]">ENDEREÇO DE ENTREGA</h3>
                        {
                            showDisabledMessage()
                        }
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Cep:</label>
                                <input name='cepInput' value={ cepInput } onChange={ e => verifyCep(e) } type="text" placeholder="Digite seu Cep. Ex: ##.###-###" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]" maxLength={10} disabled={ disabled } />
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1 text-gray-400">Rua:</label>
                                <input name='ruaInput' value={ ruaInput } onChange={ e => setRuaInput(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none" disabled/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1 text-gray-400">Numero:</label>
                                <input name='numeroInput' value={ numeroInput } onChange={ e => setNumeroInput(e.target.value) } type="number" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] outline-none" placeholder="Numero do endereço" disabled={ disabled } />
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Complemento:</label>
                                <input name='complementoInput' value={ complementoInput } onChange={ e => setComplementoInput(e.target.value) } type="text" placeholder="Complemento do endereço" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]" disabled={ disabled }/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1 text-gray-400">Bairro:</label>
                                <input name='bairroInput' value={ bairroInput } onChange={ e => setBairroInput(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none" disabled/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1 text-gray-400">Cidade:</label>
                                <input name='cidadeInput' value={ cidadeInput } onChange={ e => setCidadeInput(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none" disabled/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1 text-gray-400">Estado:</label>
                                <input name='estadoInput' value={ ufFullData } onChange={ e => setUfFullData(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none" disabled/>
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="text-sm mb-1">Telefone:</label>
                                <input name='telefoneInput' value={ telefoneInput } onChange={ e => setTelefoneInput(e.target.value) } type="text" placeholder="Digite um telefone" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1]" disabled={ disabled }/>
                            </div>
                        </div>
                    </div>
                    <div className="text-[#8b716f]">
                        <h3 className="text-sm border-b-2 pb-1 mb-2 border-[#8b716f]">MÉTODO DE ENVIO</h3>
                    </div>
                    <div className="text-[#8b716f]">
                        <h3 className="text-sm border-b-2 pb-1 mb-2 border-[#8b716f]">FORMA DE PAGAMENTO</h3>
                    </div>
                </div>
            )
        }
    }

    const checkoutFinish = () => {
        userDispatch(setLoading(null));
        
        let formDataUserOrder = new FormData();

        formDataUserOrder.append('user_id', user.user.user.id);
        formDataUserOrder.append('subtotal', cart.sale_amount.toFixed(2));
        formDataUserOrder.append('discount', '0');
        formDataUserOrder.append('tax', '0');
        formDataUserOrder.append('total', cart.sale_amount.toFixed(2));

        registerOrder(formDataUserOrder).then(
            (response) => {
                if(response) {
                    cart.items.map((item: any) => {

                        let formDataOrderItem = new FormData();

                        formDataOrderItem.append('product_id', item.product.id);
                        formDataOrderItem.append('order_id', response.order[0].id);
                        formDataOrderItem.append('quantity', item.count);

                        if(item.product.sale_price > 0)
                        {
                            formDataOrderItem.append('price', item.product.sale_price);
                        } 
                        else 
                        {
                            formDataOrderItem.append('price', item.product.regular_price);
                        }
                        

                        registerOrderItem(formDataOrderItem).then(
                            (response) => {
                                if(response) {
                                    console.log('vamos bien');
                                } else {
                                    console.log('nop');
                                }
                            }
                        );

                    });
                }
            }
        )

        userDispatch(removeLoading(null));
    }

    return (
        <Layout>
            {
                user.loading && (
                    <div className="fixed top-0 left-0 w-screen h-screen text-white flex justify-center items-center" style={{ backgroundColor: 'rgba(0,0,0,.5)' }}>
                        <div>
                            <TailSpin color='#FFFFFF' width={50} height={50} radius="1"/>
                        </div>
                    </div>
                )
            }
            <div className="container">
                <div className="my-4 flex items-center flex-wrap text-[#8b716f]">
                    <span className="text-sm"><Link to='/'>Home</Link></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <span className="text-sm text-gray-400">Finalizar Compra</span>
                </div>
                {
                    showProductsItems()
                }
                {
                    showUserDataForm()
                }
                {
                    showFormUserRegister()
                }
                <div className="w-full mt-4 flex justify-center items-center">
                    {
                        user.loading ?
                        <span className="bg-[#8b716f] w-full xl:w-1/2 cursor-pointer text-center py-3 text-white rounded-md text-sm uppercase border border-[#8b716f] transition-all duration-150 hover:bg-white hover:text-[#8b716f] mb-10">
                            <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                        </span>
                        :
                        <span onClick={ checkoutFinish } className="bg-[#8b716f] w-full xl:w-1/2 cursor-pointer text-center py-3 text-white rounded-md text-sm uppercase border border-[#8b716f] transition-all duration-150 hover:bg-white hover:text-[#8b716f] mb-10">
                            Finalizar Compra
                        </span>
                    }
                    
                </div>
            </div>
        </Layout>
    )
}

export default Checkout
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react';
import logoAlpha from '../assets/global/logoalpha.png';
import DashboardLinks from "../components/dashboard/DashboardLinks";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux/hooks";
import { logout } from "../service/userService";
import { cepMask } from "../helpers/cepmask";
import { setLoading, removeLoading } from "../redux/slice/users/userSlice";
import { getLoadUser } from '../service/userService';
import { ToastContainer,toast } from 'react-toastify';
import { updateProfileData } from "../service/userService";
import { Oval } from 'react-loader-spinner'
import { check_authenticated } from '../service/userService';

const Dashboard = () => {

    const { user } = useSelector((state:any) => state);

    const navigate = useNavigate();

    const userDispatch = useAppDispatch();

    const [ check, setCheck ] = useState(false);
    const [ cepInput, setCepInput ] = useState('');
    const [ ruaInput, setRuaInput ] = useState('');
    const [ numeroInput, setNumeroInput ] = useState('');
    const [ complementoInput, setComplementoInput ] = useState('');
    const [ bairroInput, setBairroInput ] = useState('');
    const [ cidadeInput, setCidadeInput ] = useState('');
    const [ ufInput, setUfInput ] = useState('');
    const [ ufFullData, setUfFullData ] = useState('');
    const [ telefoneInput, setTelefoneInput ] = useState('');
    const [ sidebarOpen, setSidebarOpen ] = useState(false);

    const customId = "user-profile-error";

    useEffect(() => {
        userDispatch(check_authenticated(user.access));
    }, [user.access]);

    useEffect(() => {
        const fetchData = async () => {
            await userDispatch(getLoadUser(user.access));
            setCheck(true);
        }

        fetchData();
    }, []);

    useEffect(() => {

        if(check) {
            if(!user.isAuthenticated) {
                navigate('/');
            }
        }

    }, [check]);

    useEffect(() => {
        if(user.user && user.user !== null) {
            if(user.user && user.user.user.shipping.length !== 0) {
                setCepInput(user.user.user.shipping[0].cep_number);
                setRuaInput(user.user.user.shipping[0].address);
                setNumeroInput(user.user.user.shipping[0].address_number);
                setComplementoInput(user.user.user.shipping[0].address_extra_info);
                setBairroInput(user.user.user.shipping[0].address_bairro);
                setCidadeInput(user.user.user.shipping[0].city);
                setUfFullData(user.user.user.shipping[0].state);
                setTelefoneInput(user.user.user.shipping[0].phone_number);
            }
        }
    }, [user.user]);

    function classNames(...classes:any) {
        return classes.filter(Boolean).join(' ')
    }

    const logoutHandler = (e: any) => {
        e.preventDefault();
        userDispatch(logout());
        navigate('/');
    }

    const verifyCep = (e: any) => {

        setCepInput(cepMask(e.target.value));
        
        if(e.target.value.length >= 8) {

            let cepOriginal = e.target.value;
            let cepCode = '';
            
            cepCode = cepOriginal.replace('.','').replace('-','');

            if(cepCode.length == 8) {
                userDispatch(setLoading(null));
                let url = 'http://viacep.com.br/ws/' + cepCode  + '/json/';

                fetch(url).then(response => response.json())
                    .then(data => {     
                        setRuaInput(data.logradouro);
                        setBairroInput(data.bairro);
                        setCidadeInput(data.localidade);
                        setUfInput(data.uf);

                        let urlUF = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + data.uf + '/';

                        fetch(urlUF).then(response => response.json())
                            .then(dataUf => {
                                setUfFullData(dataUf.nome);
                            })
                        
                        userDispatch(removeLoading(null));
                    });
            }
        }
    }

    const showUserData = () => {
        return (
            <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-[#8b716f]">Nome completo</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ user.user.user.name }</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-[#8b716f]">Cpf Registrado</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ user.user.user.cpf }</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-[#8b716f]">Endereço de Email</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{ user.user.user.email }</dd>
                </div>
            </dl>
        )
    }

    const showShippingInformation = () => {
        if(user && user.isAuthenticated) {
            return (
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Cep</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='cepInput' value={ cepInput } onChange={ e => verifyCep(e) } type="text" placeholder="Digite seu Cep. Ex: ##.###-###" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] w-1/2" maxLength={10} />
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Rua</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='ruaInput' value={ ruaInput } onChange={ e => setRuaInput(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none w-3/4" disabled/>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Numero</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='numeroInput' value={ numeroInput } onChange={ e => setNumeroInput(e.target.value) } type="number" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] outline-none w-1/2" placeholder="Numero do endereço"/>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Complemento</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='complementoInput' value={ complementoInput } onChange={ e => setComplementoInput(e.target.value) } type="text" placeholder="Complemento do endereço" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] w-3/4"/>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Bairro</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='bairroInput' value={ bairroInput } onChange={ e => setBairroInput(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none w-1/2" disabled/>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Cidade</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='cidadeInput' value={ cidadeInput } onChange={ e => setCidadeInput(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none w-1/2" disabled/>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Estado</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='estadoInput' value={ ufFullData } onChange={ e => setUfFullData(e.target.value) } type="text" className="border border-gray-400 bg-gray-200 rounded-md p-2 text-sm outline-none w-1/2" disabled/>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#8b716f]">Telefone</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input name='telefoneInput' value={ telefoneInput } onChange={ e => setTelefoneInput(e.target.value) } type="text" placeholder="Digite um telefone" className="border border-[#8b716f] rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-[#bdb2b1] w-1/2"/>
                        </dd>
                    </div>
                </dl>
            )
            
        }
    }

    const saveUserData = () => {
        if(cepInput.length === 0 && cepInput.length > 10) {
            toast.error("Por favor digite um cep válido.", {
                theme: "colored",
                toastId: customId
            });
            return;
        }

        if(numeroInput.length === 0) {
            toast.error("Por favor digite um numero para seu endereço.", {
                theme: "colored",
                toastId: customId
            });
            return;
        }

        if(telefoneInput.length === 0) {
            toast.error("Por favor digite um numero de telefone.", {
                theme: "colored",
                toastId: customId
            });
            return;
        }

        userDispatch(setLoading(null));

        let userDataFormData = new FormData();
        userDataFormData.append('cep_number', cepInput);
        userDataFormData.append('address', ruaInput);
        userDataFormData.append('address_number', numeroInput);
        userDataFormData.append('address_bairro', bairroInput);
        userDataFormData.append('city', cidadeInput);
        userDataFormData.append('state', ufFullData);
        userDataFormData.append('user_id', user.user.user.id);
        userDataFormData.append('phone_number', telefoneInput);

        updateProfileData(userDataFormData, user.access).then((response) => {

            userDispatch(removeLoading(null));

            if(response.data.success) {
                toast.success("Atualização de dados com exito.", {
                    theme: "colored",
                    toastId: customId
                });
            } else {
                toast.error("Error na atualização dos dados.", {
                    theme: "colored",
                    toastId: customId
                });
                return;
            }
        });
    }

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                        <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
                            <div className="relative flex-1 flex flex-col max-w-[75%] w-full pt-5 pb-4 bg-white">
                                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setSidebarOpen(false)} >
                                            <span className="sr-only">Close sidebar</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <Link to="/" className="flex justify-center items-center cursor-pointer">
                                        <img className="w-3/4" src={ logoAlpha } />
                                    </Link>
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2 space-y-1">
                                        <DashboardLinks />
                                    </nav>
                                </div>
                            </div>           
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>
                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4 justify-center">
                            <Link to="/" className="flex justify-center items-center cursor-pointer">
                                <img className="w-3/4" src={ logoAlpha } />
                            </Link>
                        </div>
                        <div className="mt-5 flex-grow flex flex-col">
                            <nav className="flex-1 px-2 pb-4 space-y-1">
                                <DashboardLinks />
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
                        <button type="button" className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                            </svg>
                        </button>
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="flex-1 flex"></div>
                            <div className="ml-4 flex items-center md:ml-6">
                                <button type="button" className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span className="sr-only">View notifications</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                    </svg>
                                </button>
                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">Open user menu</span>
                                            <span className="inline-block h-8 w-8 rounded-full overflow-hidden border border-[#6b5c5a] bg-gray-100 hover:border hover:border-[#794afa] transition-all duration-300">
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            </span>
                                        </Menu.Button>
                                    </div>
                                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <Link
                                                        to='/dashboard'
                                                        className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Meu Perfil
                                                    </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Support
                                                    </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        License
                                                    </a>
                                                    )}
                                                </Menu.Item>
                                                <form>
                                                    <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                        onClick={ logoutHandler }
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-left text-sm'
                                                        )}
                                                        >
                                                        Sair
                                                        </button>
                                                    )}
                                                    </Menu.Item>
                                                </form>
                                            </div>
                                        </Menu.Items>             
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <main className="flex-1">
                        <div className="p-6">
                            <div className="px-4 sm:px-0 flex flex-col justify-between md:flex-row">
                                <div>
                                    <h3 className="text-base text-center md:text-left font-semibold leading-7 text-gray-900">Dados pessoais</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
                                </div>
                                <div className="flex items-center justify-center">
                                    {user.loading ?
                                        <button className="w-full max-w-xs mx-auto bg-[#8b716f] hover:bg-[#dfd8d7] border-[#8b716f] border hover:text-[#8b716f] text-white rounded-lg px-3 py-3 font-semibold transition-all duration-300 flex justify-center items-center">
                                            <Oval color='#FFFFFF' width={25} height={25} strokeWidth={3} strokeWidthSecondary={3} secondaryColor="#FFFFFF" />
                                        </button>
                                        :
                                        <button type="button" onClick={ saveUserData } className="bg-[#8b716f] w-full cursor-pointer text-center px-6 py-3 text-white rounded-md text-sm uppercase border border-[#8b716f] transition-all duration-150 hover:bg-white hover:text-[#8b716f] mb-10">
                                            Salvar
                                        </button>
                                    }
                                    
                                </div>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                {
                                    user &&
                                    user.user && 
                                    user.user !== null &&
                                    user.user !== undefined &&
                                    showUserData()
                                }
                            </div>
                            <div className="mt-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Endereço de entrega</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                {
                                    showShippingInformation()
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer autoClose={5000} />
        </>
    )
}

export default Dashboard;
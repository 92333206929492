import axiosInstance from './api';
import { AxiosResponse, AxiosError } from 'axios';
import { userPostLoginRequest, userPostRegisterRequest, userRecoverPassword } from '../models/user-models';
import { Thunk } from '../redux/store';
import { setLoading, removeLoading, setRegisterUserRequest, setUserRequest, setUserRequestFail, setUserLogout, setLoadUser, setCheckUser } from '../redux/slice/users/userSlice';
import { setAlert, removeAlert } from '../redux/slice/commons/alertSlice';

const csrf = () => axiosInstance.get('/sanctum/csrf-cookie');

export const register = (data: userPostRegisterRequest): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(null));
    try {
        await csrf();
        const response: AxiosResponse = await axiosInstance.post('/api/auth/register', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        if(response.status === 201) {
            const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

            window.scrollTo(0,0)
            
            dispatch(setAlert({alertType: 'green', message: 'Usuário registrado com sucesso, um e-mail de ativação chegará em breve.'}));

            setTimeout(() => {
                dispatch(removeAlert({alertType: null, message: null}));
            }, time);

            dispatch(setRegisterUserRequest(response.data));
        } else {
            if(response.status === 500) {
                const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

                dispatch(setAlert({alertType: 'red', message: 'Falha ao registrar usuário.'}));

                setTimeout(() => {
                    dispatch(removeAlert({alertType: null, message: null}));
                }, time);
            }
        }

        dispatch(removeLoading(null));

        return response;
    } catch(error: any) {
        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        dispatch(removeLoading(null));
        
        window.scrollTo(0,0)

        const errorResponse = error.response.data.error
        
        if(errorResponse !== null || errorResponse !== 'undefined') {

            Object.keys(errorResponse).map(key => {
                dispatch(setAlert({alertType: 'red', message: `Ocorreu um erro, verifique. ${errorResponse[key]}`}));
            })

            setTimeout(() => {
                dispatch(removeAlert({alertType: null, message: null}));
            }, time);
        } else {
            dispatch(setAlert({alertType: 'red', message: 'Ocorreu um erro. Por favor tente novamente mais tarde.'}));
        
            setTimeout(() => {
                dispatch(removeAlert({alertType: null, message: null}));
            }, time);
        }

        return error as AxiosError
    }
}

export const active = (data: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(null));
    await csrf()
    try {
        const response: AxiosResponse = await axiosInstance.get(`/api/email/verify/${data.uid}/${data.token}?expires=${data.expires}&signature=${data.signature}`);
        dispatch(removeLoading(null));
        
        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'green', message: 'Conta ativada com sucesso. Obrigado.'}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return response;
    } catch(error) {
        dispatch(removeLoading(null));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'red', message: 'Ocorreu um erro. Por favor tente novamente mais tarde.'}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return error as AxiosError
    }
}

export const check_authenticated = (token: any) => async (dispatch: any) => {

    await csrf();

    let dataActive = {
        'token': token
    }

    try {

        const response: AxiosResponse = await axiosInstance.post('/api/auth/load-user', dataActive, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setCheckUser(response.data));

    } catch(error: any) { 
        dispatch(setUserRequestFail(error));
    }
}

export const login = (data: userPostLoginRequest): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(null));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.post('/api/auth/login', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        dispatch(setUserRequest(response.data));
        dispatch(removeLoading(null));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'green', message: 'Login realizado corretamente.'}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return response;
    } catch(error: any) {
        dispatch(removeLoading(null));
        dispatch(setUserRequestFail(error));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'red', message: `Ocorreu um erro. ${error.response.data.message}`}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return error as AxiosError;
    }
}

export const logout = (): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.post('/api/auth/logout', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        dispatch(setUserLogout(null));
        
        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'green', message: 'Logout realizado corretamente.'}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return response;

    } catch (error:any) {
        dispatch(setUserRequestFail(error));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'red', message: `Ocorreu um erro. ${error.response.data.message}`}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return error as AxiosError;
    }
}

export const recoveryPassword = (data: userRecoverPassword): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(null));
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.post('/api/auth/recovery_password', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        dispatch(removeLoading(null));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'green', message: 'Um e-mail para reactivar a conta chegará em breve.'}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return response;
    } catch(error: any) {
        dispatch(removeLoading(null));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'red', message: `Ocorreu um erro. ${error.response.data.message}`}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return error as AxiosError;
    }
}

export const confirmNewPassword = (data: any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(null));
    await csrf()
    try {
        const response: AxiosResponse = await axiosInstance.post(`/api/email/verify/forgot_password/${data.uid}/${data.token}?expires=${data.expires}&signature=${data.signature}`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(removeLoading(null));
        
        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'green', message: 'Senha alterada com sucesso. Obrigado.'}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return response;
    } catch(error: any) {
        dispatch(removeLoading(null));

        const time:any = process.env.REACT_APP_NOTIFICATION_TIMEOUT;

        window.scrollTo(0,0)
        
        dispatch(setAlert({alertType: 'red', message: `Ocorreu um erro. ${error.response.data.message}`}));

        setTimeout(() => {
            dispatch(removeAlert({alertType: null, message: null}));
        }, time);

        return error as AxiosError
    }
}

export const verifyUser = async (data: any) => {
    await csrf();
    try {
        const response: AxiosResponse = await axiosInstance.post('/api/auth/verify-user', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch(error: any) {

        return error;
    }
}

export const getLoadUser = (data:any): Thunk => async (dispatch): Promise<AxiosResponse | AxiosError> => {
    dispatch(setLoading(null));

    await csrf();

    try {

        let dataActive = {
            'token': data
        }

        const response: AxiosResponse = await axiosInstance.post('/api/auth/load-user', dataActive, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        dispatch(setLoadUser(response.data));

        dispatch(removeLoading(null));
        
        return response;
        
        
    } catch(error: any) {
        //dispatch(setUserRequestFail(error));

        dispatch(removeLoading(null));
        
        return error as AxiosError;
    }
}

export const updateProfileData = async (data: any, token: any) => {

    await csrf();

    try {

        const response: AxiosResponse = await axiosInstance.post('/api/orders/shipping', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        
        return response;
        
        
    } catch(error: any) {

        return error;
    }
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userModel, userRequestModel } from '../../../models/user-models';

const initialState: userRequestModel = {
    access: null,
    isAuthenticated: false,
    user: null,
    loading: false
}

export const userSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<any | null>) => {
            state.loading = true;
        },
        removeLoading: (state, action: PayloadAction<any | null>) => {
            state.loading = false;
        },
        setRegisterUserRequest: (state, action: PayloadAction<any | null>) => {
            state.user = action.payload;
        },
        setUserRequest:(state, action: PayloadAction<userModel | null>) => {
            state.user = action.payload;
            state.access = action.payload?.token;
            state.isAuthenticated = true;
        },
        setUserRequestFail: (state, action: PayloadAction<string | null | unknown>) => {
            state.access = null;
            state.isAuthenticated = false;
            state.user = null;
        },
        setUserLogout: (state, action: PayloadAction<string | null>) => {
            state.access = null;
            state.isAuthenticated = false;
            state.user = null;
        },
        setLoadUser: (state, action: PayloadAction<any | null>) => {
            if(action.payload?.success) {
                state.user = action.payload;
            } else {
                state.user = null;
            }
            
        },
        setCheckUser: (state, action: PayloadAction<any | null>) => {
            state.isAuthenticated = action.payload?.success
        }
    }
});

export default userSlice.reducer;

export const { setLoading, removeLoading, setRegisterUserRequest, setUserRequest, setUserRequestFail, setUserLogout, setLoadUser, setCheckUser } = userSlice.actions;
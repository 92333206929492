import Card1 from '../../assets/global/card1.jpg';
import Card2 from '../../assets/global/card2.jpg';
import Card3 from '../../assets/global/card3.jpg';

const BigCards = () => {
    return (
        <div className="flex justify-between items-center !py-3 px-1 sm:max-w-7xl m-auto sm:px-4 lg:px-8">
            <div className="border rounded-md mb-3 overflow-hidden md:w-80 shadow-lg">
                <a href="" className="flex flex-col">
                    <div className="w-full flex justify-center items-center bg-[#dc8e8e] border-b-4 text-xs px-4 py-1 text-white sm:text-base sm:py-2">CARTELÃO</div>
                    <div className="w-full h-full flex justify-center items-center hover:scale-150 transition-all duration-150">
                        <img src={ Card1 } />
                    </div>
                </a>
            </div>
            <div className="border rounded-md mb-3 overflow-hidden md:w-80 mx-2 shadow-lg">
                <a href="" className="flex flex-col">
                    <div className="w-full flex justify-center items-center bg-[#d66858] border-b-4 text-xs px-4 py-1 text-white sm:text-base sm:py-2">PROMOÇÕES</div>
                    <div className="w-full h-full flex justify-center items-center hover:scale-150 transition-all duration-150">
                        <img src={ Card2 } />
                    </div>
                </a>
            </div>
            <div className="border rounded-md mb-3 overflow-hidden md:w-80 shadow-lg">
                <a href="" className="flex flex-col">
                    <div className="w-full flex justify-center items-center bg-[#9d5a5d] border-b-4 text-xs px-4 py-1 text-white sm:text-base sm:py-2 md:hidden">CARTELINHA</div>
                    <div className="w-full justify-center items-center bg-[#9d5a5d] border-b-4 text-sm px-4 py-1 hidden text-white sm:text-base sm:py-2 md:flex">CARTELINHA SIMPLES</div>
                    <div className="w-full h-full flex justify-center items-center hover:scale-150 transition-all duration-150">
                        <img src={ Card3 } />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default BigCards;
import { createBrowserRouter } from 'react-router-dom';

import Home from './containers/Home';
import Error404 from './containers/errors/Error404';
import SignupPage from './containers/auth/Signup';
import SigninPage from './containers/auth/Signin';
import ActivatePage from './containers/auth/Activate';
import ResetPasswordPage from './containers/auth/ResetPassword';
import ResetPasswordConfirmPage from './containers/auth/ResetPasswordConfirm';
import Category from './containers/Category';
import Subcategory from './containers/Subcategory';
import Subcategorysub from './containers/Subcategorysub';
import Product from './containers/Product';
import SearchProduct from './containers/Search';
import Cart from './containers/Cart';
import Checkout from './containers/Checkout';
import Dashboard from './containers/Dashboard';
import DashboardPayments from './containers/DashboardPayments';
import WishlistPage from './containers/Wishlist';

const router = createBrowserRouter([
    {
        path: '*',
        element: <Error404/>  
    },
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/signup',
        element: <SignupPage />
    },
    {
        path: '/signin',
        element: <SigninPage />
    },
    {
        path: '/activate/:uid/:token',
        element: <ActivatePage />
    },
    {
        path: '/reset_password',
        element: <ResetPasswordPage />
    },
    {
        path: '/password/reset/confirm/:uid/:token',
        element: <ResetPasswordConfirmPage />
    },
    {
        path: '/category/:slug',
        element: <Category />
    },
    {
        path: '/subcategory/:slug',
        element: <Subcategory />
    },
    {
        path: '/subcategorysub/:slug',
        element: <Subcategorysub />
    },
    {
        path: '/product/:slug',
        element: <Product />
    },
    {
        path: '/product/search/:value',
        element: <SearchProduct />
    },
    {
        path: '/cart',
        element: <Cart />
    },
    {
        path: '/checkout',
        element: <Checkout />
    },
    {
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        path: '/dashboard/payments',
        element: <DashboardPayments />
    },
    {
        path: '/wishlist',
        element: <WishlistPage />
    }
]);

export default router;
import Default from '../../assets/global/default.jpg';

const OrderItem = ({ title, price, description }: any) => {
    return (
        <div className='flex mb-4 sm:mb-6'>
            <div className='w-20 rounded-md overflow-hidden mr-4 flex justify-center items-center sm:w-36'>
                <img src={ Default } alt='tes' />
            </div>
            <div className='text-sm flex flex-col justify-center w-full'>
                <div className='flex flex-col sm:flex-row sm:justify-between sm:w-full'>
                    <p className='text-[#6b5c5a] font-bold'>{ title }</p>
                    <p>{ price }</p>
                </div>
                <div className='hidden sm:block text-gray-400 text-xs'>
                    { description }
                </div>
            </div>
        </div>
    )
}

export default OrderItem;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState:any = {
    globalLoading: false
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<any>) => {
            state.globalLoading = action.payload;
        },
    }
});

export default loadingSlice.reducer;

export const { setLoading } = loadingSlice.actions;